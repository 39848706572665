import { StageType } from 'api-clients/monolith';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from './messages';

export const StageDescriptionTextMap: Record<StageType, React.ReactNode> =
  Object.freeze({
    AccurateStage: null,
    ArchivedStage: null,
    AssessmentStage: null,
    BackgroundCheckerStage: (
      <FormattedMessage
        {...messages.backgroundCheckerStageDescription}
        values={{ br: <br /> }}
      />
    ),
    'CheckrCanada::Stage': null,
    CustomStage: <FormattedMessage {...messages.customStageDescription} />,
    DataCollectionStage: (
      <FormattedMessage {...messages.dataCollectionStageDescription} />
    ),
    DistributeApplicantsRuleStage: null,
    DocumentSigningStage: null,
    DocumentSignatureStage: (
      <FormattedMessage {...messages.documentSignatureStageDescription} />
    ),
    EmailPdfStage: null,
    FilterStage: null,
    FountainDocumentSigningStage: null,
    HiredStage: null,
    I9FormStage: null,
    JobSelectorStage: (
      <FormattedMessage {...messages.jobSelectorStageDescription} />
    ),
    JobSwitcherStage: (
      <FormattedMessage {...messages.jobSwitcherStageDescription} />
    ),
    JumpStage: null,
    LearningStage: null,
    MixedStage: null,
    MultiStage: null,
    'NewCheckrCanada::Stage': null,
    OnfidoStage: null,
    PartnerStage: null,
    RejectedStage: null,
    RuleStage: null,
    SchedulerStage: (
      <FormattedMessage
        {...messages.schedulerStageDescription}
        values={{ br: <br /> }}
      />
    ),
    SchedulerV2Stage: (
      <FormattedMessage
        {...messages.schedulerV2StageDescription}
        values={{ br: <br /> }}
      />
    ),
    EventStage: (
      <FormattedMessage
        {...messages.schedulerV2StageDescription}
        values={{ br: <br /> }}
      />
    ),
    SchoolkeepStage: null,
    SterlingStage: null,
    TechCheckStage: null,
    TraitifyStage: null,
    VideoRecordingStage: null,
    W4FederalFormStage: null,
    W4StateFormStage: <FormattedMessage {...messages.w4StateFormNotice} />,
    WotcStage: null,
  });
