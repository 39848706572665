import {
  Datepicker,
  StyledReactSelect,
} from '@fountain/fountain-ui-components';
import { Box, Grid, Typography } from '@material-ui/core';
import { EventAvailableSlot } from 'api-clients/monolith';
import { DateTime } from 'luxon';
import React, { useEffect, useState, VFC } from 'react';
import { classNames } from 'react-extras';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { Error } from 'components/Error';
import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { getMinDate } from 'containers/ScheduleApplicantModal/components/AddAvailabilityModal/utils';

import { frequencyOptions, repeatEvent } from '../constants';
import { messages } from '../messages';
import { availability, generateAvailabilityTimes } from '../utils';
import { useSetAvailabilities } from './hooks/useSetAvailabilities';
import { useSplitOptions } from './hooks/useSplitOptions';
import { useStyles } from './styles';
import { DateSelectProps, EventDateTimeProps } from './types';

export const EventDateTime: VFC<EventDateTimeProps> = ({
  handleChange,
  availableSlot,
  errors,
  series,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const noSplit = intl.formatMessage(messages.noSplit);
  const currentUser = useSelector(makeSelectWhoami());
  const locale = useSelector(makeSelectLocale());
  const { time_zone: timeZone } = currentUser;
  const minDate = getMinDate(timeZone);
  const DEFAULT_SPLIT_TIME = 15;
  const DEFAULT_PLUS_TIME = 15;

  const { splitOptions, splitOption, setSplitOption } = useSplitOptions({
    startTime: availableSlot.start_time,
    endTime: availableSlot.end_time,
    noSplitLabel: noSplit,
  });

  const localizationOptions = {
    hour: 'numeric' as const,
    minute: 'numeric' as const,
    timeZone,
    timeZoneName: 'short' as const,
  };

  const {
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    availDate,
    setAvailDate,
    availableStartTimes,
    availableEndTimes,
    setAvailableEndTimes,
  } = useSetAvailabilities({
    minDate,
    availableSlot,
    handleChange,
  });

  const durationInMinutes = (startTime: string, endTime: string) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const durationInMs = end.getTime() - start.getTime();
    return durationInMs / (1000 * 60);
  };

  const [splitTime, setSplitTime] = useState(
    durationInMinutes(availableSlot.start_time, availableSlot.end_time) ||
      DEFAULT_SPLIT_TIME,
  );
  const [plusTime, setPlusTime] = useState(
    durationInMinutes(availableSlot.start_time, availableSlot.end_time) ||
      DEFAULT_PLUS_TIME,
  );

  useEffect(() => {
    if (availableSlot.split) {
      setSplitTime(availableSlot.split);
    }
  }, [availableSlot.split]);

  useEffect(() => {
    const newPlusTime = durationInMinutes(
      availableSlot.start_time,
      availableSlot.end_time,
    );
    setPlusTime(newPlusTime);
    // Duration only changes when the end_time changes.  When the start_time changes we want to use the duration to change the end time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableSlot.end_time]);

  const dateWithUpdatedTimeValue = (date: Date, timeValue: string) => {
    const availDateString = `${date.getFullYear()}-${(1 + date.getMonth())
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    const dateWithTime = timeValue.replace(
      /^\d{4}-\d{2}-\d{2}/,
      availDateString,
    );
    return dateWithTime;
  };

  const onStartDateChange = (e: DateSelectProps) => {
    const startTime = dateWithUpdatedTimeValue(availDate, e.value);
    const startDateTime = DateTime.fromISO(startTime);
    const endTimeDateStartFrom = startDateTime.plus({
      minutes: plusTime,
    });
    const currentAvailabilityEndTimes =
      startDateTime.day < endTimeDateStartFrom.day
        ? availableEndTimes.slice(-1)
        : generateAvailabilityTimes(endTimeDateStartFrom, splitTime);
    setAvailableEndTimes(currentAvailabilityEndTimes);
    setStartTime(e);
    const firstCurrentAvailabilityEndTime = currentAvailabilityEndTimes[0];
    setEndTime(firstCurrentAvailabilityEndTime);
    handleChange({ start_time: startTime });
    handleChange({ end_time: firstCurrentAvailabilityEndTime.value });
  };

  const onEndDateChange = (e: DateSelectProps) => {
    setEndTime(e);
    const endTime = dateWithUpdatedTimeValue(availDate, e.value);
    handleChange({ end_time: endTime });
  };

  const onDateChange = (e: Date | Date[]) => {
    const firstDate = Array.isArray(e) ? e[0] : e;
    setAvailDate(firstDate);

    const selectedDateTime = DateTime.fromISO(
      dateWithUpdatedTimeValue(firstDate, startTime.value),
    );
    const currentDateTime = DateTime.local();

    if (selectedDateTime < currentDateTime) {
      const currentAvailabilityStartTimes = availability(
        firstDate,
        currentDateTime.toString(),
      ).currentAvailabilityTimes;

      onStartDateChange(currentAvailabilityStartTimes[0]);
    } else {
      handleChange({
        start_time: dateWithUpdatedTimeValue(firstDate, startTime.value),
      });
      handleChange({
        end_time: dateWithUpdatedTimeValue(firstDate, endTime.value),
      });
    }
  };

  const repeatChange = (event: {
    value: 'days' | 'weeks' | 'weekdays' | '';
  }) => {
    if (event.value === '') {
      handleChange({
        frequency: undefined,
      });
    }
    handleChange({
      period: event.value as EventAvailableSlot['period'],
    });
  };

  const handleSplitChange = ({
    label,
    value,
  }: {
    label: string;
    value: number;
  }) => {
    setSplitOption({ label, value });
    const newSplit = label === noSplit ? undefined : value;
    handleChange({ split: newSplit });
  };

  return (
    <Box>
      <Grid container xs={12} className={styles.rowChild} direction="row">
        <Grid item xs={12}>
          <Typography variant="h3">
            <FormattedMessage {...messages.eventTimeAndSeries} />
          </Typography>
          <Typography
            variant="body2"
            color="textPrimary"
            className={styles.description}
          >
            <FormattedMessage {...messages.eventTimeAndSeriesDescription} />
          </Typography>

          <Grid className={styles.rowChild}>
            <Box display="flex" className={styles.gapBetween}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body2"
                  className={classNames(styles.label, styles.required)}
                >
                  <FormattedMessage {...messages.date} />{' '}
                </Typography>
                <Datepicker
                  locale={locale}
                  minDate={minDate}
                  value={availDate}
                  onDateChange={onDateChange}
                  renderDate={selectedDate => (
                    <FormattedDate dateStyle="short" value={selectedDate} />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box display="flex" className={styles.gapBetween}>
                  <Grid item xs={12} sm={6}>
                    <StyledReactSelect
                      label={intl.formatMessage(messages.repeat)}
                      options={repeatEvent}
                      getOptionLabel={(option: { label: string }) =>
                        option.label
                      }
                      getOptionValue={(option: { value: string }) =>
                        option.value
                      }
                      value={repeatEvent.find(
                        item => item.value === availableSlot.period,
                      )}
                      isDisabled={!!availableSlot.external_id}
                      className={
                        availableSlot.external_id ? styles.disable : ''
                      }
                      onChange={(event: {
                        value: 'days' | 'weeks' | 'weekdays' | '';
                      }) => repeatChange(event)}
                      autoWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledReactSelect
                      label={intl.formatMessage(messages.endRepeatAfter)}
                      options={frequencyOptions}
                      value={
                        !availableSlot.period
                          ? { label: 'Please select...' }
                          : {
                              label: availableSlot.frequency,
                              value: availableSlot.frequency,
                            }
                      }
                      getOptionLabel={(option: { label: string }) =>
                        option.label
                      }
                      getOptionValue={(option: { value: string }) =>
                        option.value
                      }
                      isDisabled={!availableSlot.period}
                      onChange={(event: { value: number }) =>
                        handleChange({
                          frequency: event.value,
                        })
                      }
                      className={
                        availableSlot.period
                          ? styles.nestedRequired
                          : styles.disable
                      }
                      autoWidth
                      error={Boolean(errors?.frequency)}
                    />
                    {errors?.user && <Error error={errors.frequency} />}
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={12} className={styles.rowChild} direction="row">
          <Grid item xs={12}>
            <Box display="flex" className={styles.gapBetween}>
              <Grid xs={12} sm={6}>
                <Box display="flex" className={styles.gapBetween}>
                  <Grid item xs={12} sm={6}>
                    <StyledReactSelect
                      label={intl.formatMessage(messages.startTime)}
                      options={availableStartTimes}
                      value={startTime}
                      onChange={onStartDateChange}
                      getOptionLabel={(option: DateSelectProps) =>
                        intl.formatTime(option.value, localizationOptions)
                      }
                      className={styles.nestedRequired}
                      error={Boolean(errors?.start_time)}
                    />
                    {errors?.start_time && <Error error={errors.start_time} />}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledReactSelect
                      label={intl.formatMessage(messages.endTime)}
                      options={availableEndTimes}
                      getOptionLabel={(option: DateSelectProps) =>
                        intl.formatTime(option.value, localizationOptions)
                      }
                      value={endTime}
                      isDisabled={series}
                      onChange={onEndDateChange}
                      className={
                        series ? styles.disable : styles.nestedRequired
                      }
                      error={Boolean(errors?.end_time)}
                    />
                    {errors?.end_time && <Error error={errors.end_time} />}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledReactSelect
                  label={intl.formatMessage(messages.splitInto)}
                  options={splitOptions}
                  value={splitOption}
                  isDisabled={!!availableSlot.external_id}
                  className={availableSlot.external_id ? styles.disable : ''}
                  getOptionLabel={(option: { label: string }) => option.label}
                  getOptionValue={(option: { value: string }) => option.value}
                  onChange={handleSplitChange}
                />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
