import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  shouldAutoAdvanceWhenLabelsComplete: {
    id: `app.containers.WorkflowEditor.components.StageDetail.settingsSwitches.messages.shouldAutoAdvanceWhenLabelsComplete`,
    defaultMessage:
      'Automatically move applicant to next stage when all labels are completed',
  },
  showStageInApplicantPortal: {
    id: `app.containers.WorkflowEditor.components.StageDetail.settingsSwitches.messages.showStageInApplicantPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
});
