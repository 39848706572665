/* eslint-disable camelcase */
import { Loader } from '@fountain/fountain-ui-components';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectAccountSlug } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { retrieveAgentConnection, retrieveAxAgent } from '../api';
import { messages } from '../messages';
import { AxAgentValues } from '../types';
import { useCustomizeChatbotStyles } from './styles';

export interface CustomizeChatbotProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  accessToken: string;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  axAgentValues: AxAgentValues;
  errors: {
    [key: string]: string;
  };
}

export const CustomizeChatbot: VFC<CustomizeChatbotProps> = ({
  handleChange,
  accessToken,
  setAxAgentValues,
  axAgentValues,
  errors,
}) => {
  const classes = useCustomizeChatbotStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fallbackMethod, setFallbackMethod] = useState<string>('Email');

  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';

  useEffect(() => {
    const fetchAgents = async () => {
      setIsLoading(true);
      try {
        const agentData = await retrieveAxAgent(
          accessToken,
          axAgentValues.axAgentId,
        );
        const agentConnectionResponse = await retrieveAgentConnection(
          accountSlug,
          axAgentValues.axAgentId,
        );

        setAxAgentValues(prev => ({
          ...prev,
          chatbotName: agentData.name,
          emailFallback: agentData.emailFallback,
          phoneNumberFallback: agentData.phoneNumberFallback,
          websiteFallback: agentData.websiteFallback,
          enableInFountainPlatforms:
            agentConnectionResponse.agent_connection
              .enable_in_fountain_platforms,
          smsEnabled:
            agentConnectionResponse.agent_connection.sms_to_chat_agent_enabled,
          brandId: agentConnectionResponse.agent_connection.brand_external_id,
          brands: agentConnectionResponse.agent_connection.available_brands,
          atsConnectionId:
            agentConnectionResponse.agent_connection.ats_connection_id,
          status: agentData.status,
        }));
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.fetchAgentsFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    };

    if (axAgentValues.axAgentId) void fetchAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, axAgentValues.axAgentId]);

  const handleFallbackMethodChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setFallbackMethod(event.target.value as string);
  };

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <Grid className={classes.container}>
      <Grid className={classes.header}>
        <Typography variant="h2">
          <FormattedMessage {...messages.customizeAiAgent} />
        </Typography>
      </Grid>
      <Grid className={classes.sectionHeader}>
        <Grid className={classes.sectionHeader}>
          <Typography variant="h3">
            <FormattedMessage {...messages.aiAgentName} />
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
        </Grid>
        <Grid className={classes.contactInfo}>
          <Grid className={classes.formInput}>
            <Typography variant="body2">
              <FormattedMessage {...messages.aiAgentName} />
            </Typography>
            <TextField
              placeholder={intl.formatMessage(messages.chatbotNamePlaceholder)}
              value={axAgentValues.chatbotName}
              fullWidth
              variant="outlined"
              size="small"
              name="chatbotName"
              error={!!errors.chatbotName}
              onChange={handleChange}
            />
            {errors.chatbotName && (
              <FormHelperText error>{errors.chatbotName}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <Grid className={classes.sectionHeader}>
          <Typography variant="h3">
            <FormattedMessage {...messages.aiAgentVisibility} />
            <Typography component="span" color="error">
              *
            </Typography>
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...messages.aiAgentVisibilityInstruction} />
          </Typography>
        </Grid>
        <Grid>
          <Grid className={classes.contactInfo}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={axAgentValues.enableInFountainPlatforms}
                  onChange={handleChange}
                  name="enableInFountainPlatforms"
                />
              }
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography variant="body1" className={classes.label}>
                    Fountain Website
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage
                      {...messages.fountainWebsiteDescription}
                    />
                  </Typography>
                </Box>
              }
              style={{ alignItems: 'flex-start', paddingLeft: '8px' }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={axAgentValues.smsEnabled}
                  onChange={handleChange}
                  name="smsEnabled"
                />
              }
              label={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                >
                  <Typography variant="body1" className={classes.label}>
                    SMS
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage {...messages.smsEnabledDescription} />
                  </Typography>
                </Box>
              }
              style={{ alignItems: 'flex-start', paddingLeft: '8px' }}
            />
          </Grid>
          {errors.visibility && (
            <FormHelperText error>{errors.visibility}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Grid className={classes.content}>
        <Grid className={classes.sectionHeader}>
          <Typography variant="h3">
            <FormattedMessage {...messages.fallbackInteractions} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...messages.fallbackInteractionsInstruction} />
          </Typography>
        </Grid>
        <Grid className={classes.contactInfo}>
          <Grid className={classes.formInput}>
            <Typography variant="body2">
              <FormattedMessage {...messages.fallbackMethod} />
            </Typography>
            <Select
              value={fallbackMethod}
              onChange={handleFallbackMethodChange}
              variant="outlined"
              fullWidth
              classes={{
                root: classes.root,
              }}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
              }}
            >
              <MenuItem value="Email" className={classes.menuItem}>
                Email
              </MenuItem>
              <MenuItem value="PhoneNumber" className={classes.menuItem}>
                PhoneNumber
              </MenuItem>
              <MenuItem value="Website" className={classes.menuItem}>
                Website
              </MenuItem>
            </Select>
          </Grid>
          {fallbackMethod === 'Email' && (
            <Grid className={classes.formInput}>
              <Typography variant="body2">
                <FormattedMessage {...messages.email} />
              </Typography>
              <TextField
                placeholder="email@domain.com"
                fullWidth
                value={axAgentValues.emailFallback}
                variant="outlined"
                size="small"
                name="emailFallback"
                onChange={handleChange}
              />
            </Grid>
          )}
          {fallbackMethod === 'PhoneNumber' && (
            <Grid className={classes.formInput}>
              <Typography variant="body2">
                <FormattedMessage {...messages.phoneNumber} />
              </Typography>
              <TextField
                placeholder="(123) 456-7890"
                fullWidth
                value={axAgentValues.phoneNumberFallback}
                variant="outlined"
                size="small"
                name="phoneNumberFallback"
                onChange={handleChange}
              />
            </Grid>
          )}
          {fallbackMethod === 'Website' && (
            <Grid className={classes.formInput}>
              <Typography variant="body2">
                <FormattedMessage {...messages.website} />
              </Typography>
              <TextField
                placeholder="www.website.com/faq"
                fullWidth
                value={axAgentValues.websiteFallback}
                variant="outlined"
                size="small"
                name="websiteFallback"
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
