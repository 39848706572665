import { StyledReactSelect } from '@fountain/fountain-ui-components';
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ChatbotWidgetBrand } from 'api-clients/monolith';
import React, { useEffect, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';

import { messages } from '../messages';
import { AxAgentValues, SelectedBrand } from '../types';
import { Footer } from './Footer';
import { useChooseBrandStyles } from './styles';
import { Widget } from './Widget';

export interface ChooseBrandProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  axAgentValues: AxAgentValues;
  accessToken: string;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  setStartChatbotProgressor: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChooseBrand: VFC<ChooseBrandProps> = ({
  currentStep,
  setCurrentStep,
  axAgentValues,
  accessToken,
  setAxAgentValues,
  setStartChatbotProgressor,
}) => {
  const intl = useIntl();
  const classes = useChooseBrandStyles();
  const { brands } = axAgentValues;
  const { default_brand_id: defaultBrandId } = useSelector(makeSelectWhoami());
  const isAllBrandSelected = axAgentValues.brandId === null;
  const [brandOption, setBrandOption] = useState(
    isAllBrandSelected ? 'all_brands' : 'individual_brand',
  );
  const isAllBrandDisabled = axAgentValues.totalChatAgents > 1;
  const defaultBrand = brands.find(item => item.key === defaultBrandId);
  const [selectedBrand, setSelectedBrand] = useState<SelectedBrand>({
    option: {} as ChatbotWidgetBrand,
    brandId: axAgentValues.brandId,
  });

  useEffect(() => {
    if (brands.length > 0) {
      const initialBrand = brands.find(
        item => item.key === axAgentValues.brandId,
      );
      const initialBrandId =
        brandOption === 'all_brands'
          ? null
          : initialBrand?.key ?? brands[0].key;

      setSelectedBrand({
        option: initialBrand ?? brands[0],
        brandId: initialBrandId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands]);

  useEffect(() => {
    if (Object.keys(selectedBrand.option).length > 0) {
      setAxAgentValues(prev => ({
        ...prev,
        brandName: selectedBrand.option.label,
        brandId: selectedBrand.brandId as string,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrand]);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBrandOption(event.target.value);
    if (event.target.value === 'all_brands') {
      setSelectedBrand({
        option: defaultBrand as ChatbotWidgetBrand,
        brandId: null,
      });
    } else {
      setSelectedBrand({
        option: selectedBrand.option,
        brandId: selectedBrand.option.key,
      });
    }
  };

  const handleBrandChange = (option: ChatbotWidgetBrand) => {
    const brandId = option.key;

    setSelectedBrand({ option, brandId } as SelectedBrand);
  };

  return (
    <Grid className={classes.container}>
      <Grid className={classes.contentContainer}>
        <Grid className={classes.header}>
          <Typography variant="h2">
            <FormattedMessage {...messages.customizeWidgetAppearance} />
          </Typography>
          <Grid className={classes.sectionHeader}>
            <Typography variant="h3">
              <FormattedMessage {...messages.chooseBrand} />
            </Typography>
            <Typography variant="body2">
              <FormattedMessage {...messages.chooseBrandDescription} />
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.content}>
          <Box>
            <RadioGroup
              name="choose_brand"
              value={brandOption}
              onChange={handleRadioChange}
              style={{ gap: '16px' }}
            >
              <Tooltip
                title={
                  isAllBrandDisabled
                    ? intl.formatMessage(messages.allBrandDisableMessage)
                    : ''
                }
                disableHoverListener={!isAllBrandDisabled}
                placement="top"
              >
                <Box>
                  <FormControlLabel
                    value="all_brands"
                    disabled={isAllBrandDisabled}
                    control={
                      <Radio color="primary" style={{ paddingTop: '4px' }} />
                    }
                    label={
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Typography
                          variant="body1"
                          display="inline"
                          className={
                            isAllBrandDisabled
                              ? classes.disabledText
                              : classes.label
                          }
                        >
                          All brands
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          display="block"
                          className={
                            isAllBrandDisabled
                              ? classes.disabledText
                              : classes.label
                          }
                        >
                          <FormattedMessage {...messages.allBrandDescription} />
                        </Typography>
                      </Box>
                    }
                  />
                </Box>
              </Tooltip>
              <FormControlLabel
                value="individual_brand"
                control={
                  <Radio color="primary" style={{ paddingTop: '0px' }} />
                }
                label={
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="body1"
                      display="inline"
                      className={classes.label}
                    >
                      Individual brand
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      display="block"
                    >
                      <FormattedMessage
                        {...messages.individualBrandDescription}
                      />
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>

            {brandOption === 'individual_brand' && (
              <>
                <StyledReactSelect
                  options={axAgentValues.brands}
                  value={selectedBrand.option}
                  onChange={handleBrandChange}
                  placeholder={intl.formatMessage(
                    messages.selectBrandPlaceholder,
                  )}
                  label={intl.formatMessage(messages.brand)}
                  getOptionLabel={(brand: ChatbotWidgetBrand) => brand.label}
                  getOptionValue={(brand: ChatbotWidgetBrand) => brand.key}
                  className={classes.chooseBrandInput}
                />
              </>
            )}
          </Box>
        </Grid>
        <Footer
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          axAgentValues={axAgentValues}
          accessToken={accessToken}
          setStartChatbotProgressor={setStartChatbotProgressor}
          setAxAgentValues={setAxAgentValues}
        />{' '}
      </Grid>
      <Grid className={classes.widgetContainer}>
        <Widget
          axAgentId={axAgentValues.axAgentId}
          selectedBrand={selectedBrand}
        />
      </Grid>
    </Grid>
  );
};
