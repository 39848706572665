import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    container: {
      marginTop: theme.spacing(3),
    },
    description: {
      marginTop: theme.spacing(1),
    },
    btnAddCondition: {
      marginTop: theme.spacing(3),
      border: 0,
      background: theme.palette.common.blue50,
    },
    separator: {
      width: 'max-content',
      fontWeight: theme.typography.h5.fontWeight,
      marginTop: theme.spacing(2),
      padding: theme.spacing(0.5, 1),
      background: theme.palette.common.gray100,
      fontSize: theme.typography.h5.fontSize,
      borderRadius: '5px',
      color: theme.palette.common.black,
    },
  }),
);
