/* eslint-disable camelcase */
import {
  Button,
  InnerContent,
  ModalFooter,
  SimpleModal,
  SimpleModalHeader,
} from '@fountain/fountain-ui-components';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  CancelablePromise,
  OpeningsService,
  Stage,
} from 'api-clients/monolith';
import { useForm } from 'hooks';
import React, { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import ChangeIconBlue from 'images/change-icon-blue.svg';
import globalMessages from 'shared/global/messages';

import { messages } from './messages';
import { StageMapper } from './StageMapper';
import { useStyles } from './styles';
import { useWorkflowWithStages } from './useWorkflowWithStages';
import { WorkflowSelect } from './WorkflowSelect';

export interface ChangeWorkflowModalProps {
  closeModal: () => void;
  funnelHasApplicants: boolean;
  funnelId: string;
  funnelName: string;
  workflowId: string;
  workflowName: string;
}

interface FormValues {
  sourceOpeningId: string;
  destintationWorkflowId: string;
  destinationWorkflowTitle: string;
  destinationWorkflowLocale?: string;
  stageMappings: Record<string, Stage>;
  runLandingActions: boolean;
}

export const ChangeWorkflowModal: FC<ChangeWorkflowModalProps> = ({
  closeModal,
  funnelHasApplicants,
  funnelId,
  funnelName,
  workflowId,
  workflowName,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { result: sourceStagesResult } = useWorkflowWithStages(workflowId);
  const { mutation: remapWorkflow } = useApiServiceMutation<
    void,
    (requestBody: {
      destination_workflow_id: string;
      destination_workflow_title: string | null;
      run_landing_actions: boolean;
      source_opening_id: string;
      stage_mappings: {
        source_stage_id?: string;
        destination_stage_id?: string;
      }[];
    }) => CancelablePromise<void>
  >(
    // eslint-disable-next-line @typescript-eslint/unbound-method
    OpeningsService.postInternalApiOpeningsWorkflowReassignment,
    {
      onSuccess: () => {
        dispatch(
          addMessageAction(intl.formatMessage(messages.success), 'success'),
        );
        // TODO: spike removing the below line in favor of refetching data based on page location ie refetchOpenings, etc.
        window.location.reload();
      },
      onError: () => {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.failure),
            'error',
            false,
          ),
        );
      },
    },
  );

  const onSubmit = (values: FormValues) => {
    remapWorkflow({
      source_opening_id: values.sourceOpeningId,
      destination_workflow_id: values.destintationWorkflowId,
      stage_mappings: Object.keys(values.stageMappings).map(key => ({
        source_stage_id: key,
        destination_stage_id: values.stageMappings[key].id,
      })),
      run_landing_actions: values.runLandingActions,
      destination_workflow_title: null,
    });
    closeModal();
  };
  const validate = (values: Partial<FormValues>) => {
    const errors: Partial<Record<keyof FormValues, string>> = {};

    if (!values.sourceOpeningId) {
      errors.sourceOpeningId = 'Need a source Opening.';
    }
    if (!values.destintationWorkflowId) {
      errors.destintationWorkflowId = 'Need a destination Workflow.';
    }
    if (sourceStagesResult.status === 'ready' && funnelHasApplicants) {
      if (sourceStagesResult.data.stages.length > 0 && !values.stageMappings) {
        errors.stageMappings = 'Need stage mappings';
      }
      const selectedSourceStageIds = Object.keys(values.stageMappings ?? {});
      const remainingRequiredMappings = sourceStagesResult.data.stages.filter(
        stage =>
          // problem if we have no mapping for a regular, top-level stage
          !selectedSourceStageIds.includes(stage.id) && !stage.parent_stage_id,
      );
      if (remainingRequiredMappings.length > 0) {
        errors.stageMappings = 'Missing stage mappings';
      }
    }

    return errors;
  };
  const { values, errors, handleChange, handleSubmit } = useForm<FormValues>(
    onSubmit,
    validate,
    {
      sourceOpeningId: funnelId,
      destintationWorkflowId: '',
      destinationWorkflowTitle: '',
      stageMappings: {},
      runLandingActions: false,
    },
  );

  const sourceStagesReady = sourceStagesResult.status === 'ready';

  const showStageMappings =
    sourceStagesReady && values.destintationWorkflowId && funnelHasApplicants;

  const localeMismatch =
    sourceStagesReady &&
    values.destinationWorkflowLocale &&
    values.destinationWorkflowLocale !== sourceStagesResult.data.locale;

  const setStageMappings = useCallback(
    (newMappings: Record<string, Stage>) => {
      handleChange({
        stageMappings: newMappings,
      });
      errors.stageMappings = undefined;
    },
    [errors, handleChange],
  );

  return (
    <SimpleModal onClose={closeModal} maxWidth={640} blurOverlay={false}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <SimpleModalHeader onClose={closeModal}>
          <div className={classes.headerContainer}>
            <img
              src={ChangeIconBlue}
              alt="change-icon"
              className={classes.icon}
            />
            <Typography variant="h3">
              <FormattedMessage {...messages.changeWorkflow} />
            </Typography>
          </div>
        </SimpleModalHeader>
        <InnerContent>
          <Typography variant="body2" className={classes.body}>
            <FormattedMessage
              {...messages.selectWorkflow}
              values={{ openingName: funnelName }}
            />
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} className={classes.workflowSelect}>
              <WorkflowSelect
                onChange={workflow =>
                  handleChange({
                    destintationWorkflowId: workflow.id,
                    destinationWorkflowTitle: workflow.title,
                    destinationWorkflowLocale: workflow.locale,
                    stageMappings: {},
                  })
                }
                onClear={() =>
                  handleChange({
                    destintationWorkflowId: '',
                    destinationWorkflowTitle: '',
                    destinationWorkflowLocale: undefined,
                    stageMappings: {},
                  })
                }
                error={errors.destintationWorkflowId}
                workflowId={values.destintationWorkflowId}
                fromWorkflowId={workflowId}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.runLandingActions}
                    onChange={() =>
                      handleChange({
                        runLandingActions: !values.runLandingActions,
                      })
                    }
                  />
                }
                label={
                  <Typography variant="body2" className={classes.checkboxText}>
                    <FormattedMessage {...messages.runLandingActions} />
                  </Typography>
                }
              />
            </Grid>
            {localeMismatch && (
              <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">
                  <FormattedMessage
                    {...messages.MismatchLocale}
                    values={{ locale: values.destinationWorkflowLocale }}
                  />
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              {showStageMappings && (
                <StageMapper
                  error={errors.stageMappings}
                  fromWorkflowTitle={workflowName}
                  destinationWorkflowTitle={values.destinationWorkflowTitle}
                  destinationWorkflowId={values.destintationWorkflowId}
                  sourceStages={sourceStagesResult.data.stages}
                  stageMappings={values.stageMappings}
                  setStageMappings={setStageMappings}
                />
              )}
              {sourceStagesResult.isError && (
                <Typography variant="body2" color="error">
                  <FormattedMessage {...messages.unableToLoadSourceStages} />
                </Typography>
              )}
              {!funnelHasApplicants && (
                <Typography variant="body2">
                  <FormattedMessage {...messages.funnelHasNoApplicants} />
                </Typography>
              )}
            </Grid>
          </Grid>
        </InnerContent>
        <ModalFooter className={classes.footer}>
          <Button autoWidth onClick={closeModal} size="small" type="secondary">
            <FormattedMessage {...globalMessages.cancel} />
          </Button>
          <Button
            autoWidth
            disabled={!values.destintationWorkflowId}
            size="small"
            submit
            type="primary"
          >
            <FormattedMessage {...globalMessages.save} />
          </Button>
        </ModalFooter>
      </form>
    </SimpleModal>
  );
};

export default ChangeWorkflowModal;
