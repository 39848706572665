import { Loader } from '@fountain/fountain-ui-components';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import {
  CancelablePromise,
  Event,
  EventsService,
  Unauthorized,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import React, { useEffect, useRef, useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import {
  EventQuery,
  EventStageOption,
  EventUserOption,
  useEvents,
  useUsersOptions,
} from '../hooks';
import { AddEventModalContainer } from './AddEventModalContainer';
import { eventManagerContext, EventModalVariant } from './context';
import { DeleteEventModal } from './DeleteEventModal';
import { EventsContainer } from './EventsContainer';
import { Header } from './Header';
import { messages } from './messages';

const useStyles = makeStyles(theme =>
  createStyles({
    pageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        gap: theme.spacing(2.5),
      },
    },
    headerWrapper: {
      display: 'flex',
      gap: theme.spacing(1.5),
      flexDirection: 'column',
    },
  }),
);

export interface EventManagerProps {
  scrollParent: HTMLDivElement | null;
}

export const EventManager: VFC<EventManagerProps> = ({ scrollParent }) => {
  const intl = useIntl();
  const FETCH_DISTANCE = 1000;
  const styles = useStyles();
  const dispatch = useDispatch();
  const [pages, setPages] = useState<Event[][]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event>();
  const [stageOptions, setStageOptions] = useState<EventStageOption[]>([]);
  const [userOptions, setUserOptions] = useState<EventUserOption[]>([]);
  const { result: userOptionsResult } = useUsersOptions();
  const { filters, setFilters, data, lastResult, next, refetch } = useEvents();

  const {
    off: setAddEventModalClosed,
    on: setAddEventModalOpen,
    showContent: addEventModalOpen,
  } = useSimpleToggle();
  const {
    on: setDeleteEventModalOpen,
    off: setDeleteEventModalClosed,
    showContent: deleteEventModalOpen,
  } = useSimpleToggle();
  const [eventModalVariant, setEventModalVariant] =
    useState<EventModalVariant>('create');
  const scrollContent = useRef<null | HTMLDivElement>(null);
  // TODO can whoami drive canManage?
  const canManage = false;

  const onSuccess = () => {
    setDeleteEventModalClosed();
    setSelectedEvent(undefined);
    refetch();

    dispatch(
      addMessageAction(intl.formatMessage(messages.deleteSuccess), 'success'),
    );
  };

  const onError = (response: Unauthorized | undefined) => {
    setDeleteEventModalClosed();
    setSelectedEvent(undefined);
    dispatch(
      addMessageAction(
        response?.error ?? intl.formatMessage(messages.error),
        'error',
      ),
    );
  };

  const { mutation: deleteEvent, result: deleteResult } = useApiServiceMutation<
    void,
    (
      eventExternalId: string,
      {
        // eslint-disable-next-line camelcase
        delete_siblings,
      }: {
        // eslint-disable-next-line camelcase
        delete_siblings: boolean;
      },
    ) => CancelablePromise<void>,
    Unauthorized
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(EventsService.deleteInternalApiEvents, {
    onSuccess,
    onError,
  });

  const handleDeleteEvent = (externalId: string) => {
    deleteEvent(externalId, { delete_siblings: true });
  };

  const handleCreateEvent = () => {
    setEventModalVariant('create');
    setAddEventModalOpen();
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        scrollContent.current &&
        scrollParent &&
        scrollContent.current.scrollHeight - scrollParent.scrollTop <
          FETCH_DISTANCE
      ) {
        next();
      }
    };
    scrollParent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollParent?.removeEventListener('scroll', handleScroll);
    };
  }, [next, scrollContent, scrollParent]);

  useEffect(() => {
    if (userOptionsResult.status === 'ready') {
      setUserOptions(userOptionsResult.data);
    }
  }, [userOptionsResult]);

  const updateFiltersEffects = (filters: EventQuery) => {
    setFilters({ ...filters, page: 0 });
    refetch(true);
  };

  useEffect(() => {
    setPages(data);
  }, [data]);

  const handleCloseAddEventModal = () => {
    setAddEventModalClosed();
    setSelectedEvent(undefined);
  };

  const handleCloseDeleteEventModal = () => {
    setSelectedEvent(undefined);
    setDeleteEventModalClosed();
  };

  const handleRefetch = () => {
    refetch();
    setEventModalVariant('create');
  };

  return (
    <>
      {lastResult.status === 'loading' && <Loader fullScreen />}
      <eventManagerContext.Provider
        value={{
          pages,
          setPages,
          setEventModalVariant,
          eventModalVariant,
          stageOptions,
          setStageOptions,
          userOptions,
          selectedEvent,
          setSelectedEvent,
          filters,
          canManage,
        }}
      >
        <div className={styles.pageWrapper} ref={scrollContent}>
          <div className={styles.headerWrapper}>
            <Typography variant="h3">
              <FormattedMessage {...messages.scheduledSessions} />
            </Typography>
          </div>
          <Header
            onUpdate={updateFiltersEffects}
            onHandleCreate={handleCreateEvent}
          />
          <EventsContainer
            setDeleteEventModalOpen={setDeleteEventModalOpen}
            setAddEventModalOpen={setAddEventModalOpen}
            initialLoad={lastResult.status === 'ready'}
          />
        </div>

        {addEventModalOpen && (
          <AddEventModalContainer
            refetch={handleRefetch}
            onClose={handleCloseAddEventModal}
          />
        )}
        {deleteEventModalOpen && (
          <DeleteEventModal
            eventExternalId={selectedEvent?.external_id}
            onCancel={handleCloseDeleteEventModal}
            deleteEvent={handleDeleteEvent}
            isLoading={deleteResult.isLoading}
          />
        )}
      </eventManagerContext.Provider>
    </>
  );
};
