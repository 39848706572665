import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Filterable } from 'components/FilterDropdown/util';

import { ConditionBuilder } from '../ConditionBuilder/ConditionBuilder';
import { conditionTypes } from '../constants';
import { messages } from '../messages';
import { useStyles } from './styles';
import { StageSelectorProps } from './types';

interface ConditionOption {
  type: string;
  values: Filterable[];
}

export const StageSelector: React.VFC<StageSelectorProps> = ({
  handleChange,
  errors,
  availableSlot,
}) => {
  const styles = useStyles();
  const buildConditions = (): ConditionOption[] => {
    const defaultConditions: ConditionOption[] = [
      { type: 'stage', values: [] },
    ];
    const conditions: ConditionOption[] = [];

    if (availableSlot?.stage_titles) {
      const stageTitles: Filterable[] = availableSlot.stage_titles.map(
        stageTitle => ({
          id: stageTitle,
          name: 'stage',
          title: stageTitle,
        }),
      );
      if (stageTitles.length > 0) {
        conditions.push({ type: 'stage', values: stageTitles });
      }
    }

    if (availableSlot?.opening_titles) {
      const openingTitles: Filterable[] = availableSlot.opening_titles.map(
        openingTitle => ({
          id: openingTitle,
          name: 'opening',
          title: openingTitle,
        }),
      );
      if (openingTitles.length > 0) {
        conditions.push({ type: 'opening', values: openingTitles });
      }
    }
    if (availableSlot?.location_group_titles) {
      const locationGroupTitles: Filterable[] =
        availableSlot?.location_group_titles.map(locationGroupTitle => ({
          id: locationGroupTitle,
          name: 'locationGroup',
          title: locationGroupTitle,
        }));
      if (locationGroupTitles.length > 0) {
        conditions.push({
          type: 'location_group',
          values: locationGroupTitles,
        });
      }
    }

    return conditions.length > 0 ? conditions : defaultConditions;
  };

  const [dynamicConditions, setDynamicConditions] = useState<ConditionOption[]>(
    buildConditions(),
  );

  useEffect(() => {
    if (
      !availableSlot?.stage_titles &&
      !availableSlot?.opening_titles &&
      !availableSlot?.location_group_titles
    ) {
      handleChange({ stage_titles: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddCondition = () => {
    const existingTypes = dynamicConditions.map(condition => condition.type);
    const newType = conditionTypes
      .map(conditionType => conditionType.value)
      .find(type => !existingTypes.includes(type));
    if (newType) {
      setDynamicConditions([
        ...dynamicConditions,
        { type: newType, values: [] },
      ]);
    }
  };

  return (
    <Grid className={styles.container}>
      <div>
        <Typography variant="h3">
          <FormattedMessage {...messages.scheduleStageSelector} />
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          className={styles.description}
        >
          <FormattedMessage {...messages.stageSelectorDescription} />
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
          className={styles.container}
        >
          <FormattedMessage {...messages.conditionSelections} />
        </Typography>
        <div className={styles.separator}> CONDITION </div>
        <>
          {/* Render dynamic condition sections */}
          {dynamicConditions.map((condition, index) => (
            <ConditionBuilder
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              condition={condition}
              index={index}
              dynamicConditions={dynamicConditions}
              setDynamicConditions={setDynamicConditions}
              handleChange={handleChange}
              errors={errors}
            />
          ))}
        </>

        {dynamicConditions.length < 3 && (
          <Button
            type="button"
            size="small"
            color="primary"
            onClick={handleAddCondition}
            className={styles.btnAddCondition}
          >
            + Add conditions
          </Button>
        )}
      </div>
    </Grid>
  );
};
