import { useCallback, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';

type ValidationResult<T> = Partial<Record<keyof T, string>>;

export function useForm<FormData extends object>(
  onSubmit: (values: FormData) => void,
  validate: (values: FormData, intl?: IntlShape) => ValidationResult<FormData>,
  defaultValues: FormData,
) {
  const [values, setValues] = useState<FormData>(defaultValues);
  const [errors, setErrors] = useState<ValidationResult<FormData>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const intl = useIntl();

  const resetForm = (valueOverrides?: Partial<FormData>) => {
    setValues(
      valueOverrides ? { ...defaultValues, ...valueOverrides } : defaultValues,
    );
    setErrors({});
    setIsSubmitting(false);
  };

  const runValidations = () => {
    const newErrors = validate(values, intl);
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    const valid = runValidations();
    setIsSubmitting(true);
    if (valid) {
      onSubmit(values);
    }
  };

  const handleChange = useCallback((params: Partial<FormData>) => {
    setValues(v => ({ ...v, ...params }));
    setIsSubmitting(false);
  }, []);

  return {
    isSubmitting,
    handleChange,
    handleSubmit,
    values,
    errors,
    runValidations,
    resetForm,
  };
}

export default useForm;
