/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailMessageContent } from '../models/EmailMessageContent';
import type { Emails } from '../models/Emails';
import type { HiringGoal } from '../models/HiringGoal';
import type { HiringGoalBulkCreateUpdateResponse } from '../models/HiringGoalBulkCreateUpdateResponse';
import type { Opening } from '../models/Opening';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ApiSelfServeV2Service {

    /**
     * index
     * Returns list of email conversations
     * @param externalId Applicant's external_id
     * @returns Emails success
     * @throws ApiError
     */
    public static getApiSelfServeV2ApplicantsEmailConversations(
        externalId: string,
    ): CancelablePromise<Emails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/applicants/{external_id}/email_conversations',
            path: {
                'external_id': externalId,
            },
        });
    }

    /**
     * email_content
     * Returns email content
     * @param externalId Applicant's external_id
     * @param emailMessageExternalId EmailMessage external_id
     * @returns EmailMessageContent success
     * @throws ApiError
     */
    public static getApiSelfServeV2ApplicantsEmailMessagesEmailContent(
        externalId: string,
        emailMessageExternalId: string,
    ): CancelablePromise<EmailMessageContent> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/applicants/{external_id}/email_messages/{email_message_external_id}/email_content',
            path: {
                'external_id': externalId,
                'email_message_external_id': emailMessageExternalId,
            },
        });
    }

    /**
     * resubmit_partner_data
     * Re-sends partner data for a specified applicant
     * @param externalId External ID for the applicant
     * @param requestBody Partner information
     * @returns any success
     * @throws ApiError
     */
    public static postApiSelfServeV2ApplicantsResubmitPartnerData(
        externalId: string,
        requestBody: {
            partner_id?: string;
            partner_stage_id?: string;
        },
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/applicants/{external_id}/resubmit_partner_data',
            path: {
                'external_id': externalId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Unauthorized`,
            },
        });
    }

    /**
     * index
     * Returns Hiring Goals
     * @param openingId filter query for opening_id
     * @param open filter query for open scope
     * @param page pagination setting for page to view
     * @param perPage pagination setting for max number of records to return
     * @param current filter query for current scope
     * @param past filter query for past scope
     * @param sortBy filter query for sort_by scope
     * @param sortDirection filter query for sort_direction scope
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2BulkHiringGoals(
        openingId: string,
        open: string,
        page: string,
        perPage: string,
        current?: string,
        past?: string,
        sortBy?: string,
        sortDirection?: string,
    ): CancelablePromise<{
        hiring_goals: Array<HiringGoal>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/bulk_hiring_goals',
            query: {
                'opening_id': openingId,
                'open': open,
                'current': current,
                'past': past,
                'page': page,
                'per_page': perPage,
                'sort_by': sortBy,
                'sort_direction': sortDirection,
            },
        });
    }

    /**
     * create_or_update
     * Bulk Creates or Updates Hiring Goals
     * @param requestBody Hiring Goals
     * @returns HiringGoalBulkCreateUpdateResponse success
     * @throws ApiError
     */
    public static postApiSelfServeV2BulkHiringGoalsCreateOrUpdate(
        requestBody: {
            goals: Array<{
                goal: number;
                hire_by: string;
                start_date?: string;
                funnel_id?: string;
                external_id?: string;
                form_index?: number;
            }>;
            funnel_id: string;
        },
    ): CancelablePromise<HiringGoalBulkCreateUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api_self_serve/v2/bulk_hiring_goals/create_or_update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * destroy
     * Closes or Soft Deletes a Hiring Goal
     * @param externalId Hiring Goal External ID
     * @returns void
     * @throws ApiError
     */
    public static deleteApiSelfServeV2BulkHiringGoals(
        externalId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api_self_serve/v2/bulk_hiring_goals/{external_id}',
            path: {
                'external_id': externalId,
            },
            errors: {
                401: `unauthorized`,
                422: `unprocessable`,
            },
        });
    }

    /**
     * index
     * Returns location groups
     * @param query Filter location groups returned by matching title
     * @param enterprise Excludes scheduler v2
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2LocationGroups(
        query?: string,
        enterprise?: boolean,
    ): CancelablePromise<{
        location_groups: Array<{
            name?: string;
            id?: string;
        }>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/location_groups',
            query: {
                'query': query,
                'enterprise': enterprise,
            },
        });
    }

    /**
     * index
     * Returns Openings and Pagination
     * @param workflowId Determines which workflows to return openings from
     * @param title Filter openings returned by matching title
     * @param page Determines which page to return
     * @param active Determines whether to return active or inactive openings
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2Openings(
        workflowId?: string,
        title?: string,
        page?: number,
        active?: string,
    ): CancelablePromise<{
        openings: Array<Opening>;
        pagination: {
            current_page?: number;
            next_page?: number | null;
            per_page?: number;
            total?: number;
        };
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/openings',
            query: {
                'workflow_id': workflowId,
                'title': title,
                'page': page,
                'active': active,
            },
        });
    }

    /**
     * show
     * Returns a single opening JSON representation
     * @param externalId Opening#external_id
     * @returns any success
     * @throws ApiError
     */
    public static getApiSelfServeV2Openings1(
        externalId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/openings/{external_id}',
            path: {
                'external_id': externalId,
            },
        });
    }

    /**
     * feature flag values for user
     * Returns an object with feature flag values for the specified user.
     * @param id User's external_id
     * @returns any successful
     * @throws ApiError
     */
    public static getApiSelfServeV2UsersFeatures(
        id: string,
    ): CancelablePromise<{
        features: any;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api_self_serve/v2/users/{id}/features',
            path: {
                'id': id,
            },
        });
    }

}