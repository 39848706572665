import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    rowChild: {
      marginTop: theme.spacing(2),
    },
    description: {
      marginTop: theme.spacing(1),
    },
    columnContainer: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2),
      },
    },
    label: {
      marginBottom: theme.spacing(0.5),
    },
    nestedRequired: {
      '& p': {
        '&::after': {
          content: "'*'",
          color: theme.palette.common.red400,
          margin: theme.spacing(0.5),
        },
      },
    },
    required: {
      '&::after': {
        content: "'*'",
        color: theme.palette.common.red400,
        margin: theme.spacing(0.5),
      },
    },
    rowContainer: {
      marginTop: theme.spacing(3),
    },
    subLabel: {
      marginTop: theme.spacing(0.5),
    },
    gapBetween: {
      gap: theme.spacing(2),
    },
    disable: {
      opacity: '0.5',
    },
  }),
);
