import {
  WorkflowAccurateStage,
  WorkflowBackgroundCheckerStage,
  WorkflowSterlingStage,
} from 'api-clients/monolith';
import React, { VFC } from 'react';

import { IdleMoveRule } from 'containers/WorkflowEditor/components/IdleMoveRule';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { ActionsCard } from './ActionsCard';
import { IntegrationCard } from './IntegrationCard';
import { JobLocation } from './JobLocation';
import { NotificationsCard } from './NotificationsCard';

export interface BackgroundCheckStageProps {
  stage:
    | WorkflowAccurateStage
    | WorkflowBackgroundCheckerStage
    | WorkflowSterlingStage;
}

export const BackgroundCheckStage: VFC<BackgroundCheckStageProps> = ({
  stage,
}) => {
  return (
    <div data-testid="backgroundCheckStage">
      <StageSection>
        <IntegrationCard stage={stage} />
      </StageSection>
      <StageSection>
        <ActionsCard stage={stage} />
      </StageSection>
      <StageSection>
        <NotificationsCard stage={stage} />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      {stage.type === 'AccurateStage' && (
        <StageSection>
          <JobLocation stage={stage} />
        </StageSection>
      )}
      <StageSection>
        <StageSettingMessageCard
          messageType="consent"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
    </div>
  );
};
