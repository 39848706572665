import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  connect: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.connect`,
    defaultMessage: 'Connect',
  },
  connectToPartner: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.connectToPartner`,
    defaultMessage: 'Connect to {partnerName} to configure options',
  },
  integrationName: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.integrationName`,
    defaultMessage: '{partnerName} Integration',
  },
  manageIntegration: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.manageIntegration`,
    defaultMessage: 'Manage Integration',
  },
  mustBeConnected: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.mustBeConnected`,
    defaultMessage:
      'Must be connected to the partner integration, please connect.',
  },
  refreshOptions: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.refreshOptions`,
    defaultMessage: 'Refresh options from {partnerName}',
  },
  showStageInApplicantPortal: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.showStageInApplicantPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
  stageOptionType: {
    id: `app.components.WorkflowEditorContent.StageDetail.PartnerStage.PartnerIntegrationCard.stageOptionType`,
    defaultMessage: 'Stage Option Type',
  },
});
