import { defineMessages } from 'react-intl';

const scope = 'app.components.AssessmentFormModal';

export const messages = defineMessages({
  answeredCorrect: {
    id: `${scope}.answeredCorrect`,
    defaultMessage: 'Answered Correct: {score}',
  },
  completionDate: {
    id: `{scope}.completionDate`,
    defaultMessage: 'Completed on {completionDate}',
  },
  correctAnswer: {
    id: `{scope}.correctAnswer`,
    defaultMessage: '(correct answer)',
  },
  weightedScore: {
    id: `{scope}.weightedScore`,
    defaultMessage:
      '{points, plural,=0 {0 points} one {# point} other {# points}}',
  },
  applicantErrorToast: {
    id: `{scope}.applicantErrorToast`,
    defaultMessage: 'Applicant data not found',
  },
  tooltip: {
    id: `{scope}.tooltip`,
    defaultMessage:
      "These are the candidate's recorded answers to this assessment",
  },
});
