import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  DataType,
  MergedDetail,
  ValidationGroupDataField,
} from 'containers/DataValidationModal/types';
import useApplicantDetail from 'hooks/useApplicantDetail';
import { ApplicantDetailDataDetailsData } from 'hooks/useApplicantDetail.d';
import useValidationGroup from 'hooks/useValidationGroup';

import { makeSelectValidationGroup } from './selectors';

/* eslint-disable camelcase */
interface DataFieldsMeta {
  dataType: DataType;
  filter: (dataField: ValidationGroupDataField) => boolean;
}

interface UseDataFieldsOptions {
  applicantId?: null | string;
  validationGroupId?: null | string;
}
/* eslint-enable camelcase */

const dataFieldsMeta: DataFieldsMeta[] = [
  {
    dataType: 'data',
    filter: dataField => dataField && !dataField.predefined,
  },
  {
    dataType: 'structured_data',
    filter: dataField => dataField && dataField.predefined && !dataField.secure,
  },
  {
    dataType: 'secure_data',
    filter: dataField => dataField && dataField.predefined && dataField.secure,
  },
];

const map =
  (
    applicantDataFields: ApplicantDetailDataDetailsData[],
    dataFieldType: DataType,
  ) =>
  (validationGroupDataField: ValidationGroupDataField): MergedDetail => ({
    ...(applicantDataFields.find(
      applicantDataField =>
        applicantDataField.key === validationGroupDataField.key,
    ) ?? {}),
    ...validationGroupDataField,
    dataFieldType,
    title: validationGroupDataField.question,
    subtitle: validationGroupDataField.hint,
  });

export const useDataFields = ({
  applicantId,
  validationGroupId,
}: UseDataFieldsOptions) => {
  const { isLoading: isLoadingValidationGroup } =
    useValidationGroup(validationGroupId);

  const { data: applicantData, isFetching: isFetchingApplicantDetail } =
    useApplicantDetail(applicantId);

  const validationGroup = useSelector(makeSelectValidationGroup());

  const isLoading =
    isLoadingValidationGroup ||
    isFetchingApplicantDetail ||
    !validationGroup ||
    isEmpty(validationGroup) ||
    isEmpty(applicantData);

  if (isLoading) {
    return { isLoading, dataFields: [] };
  }

  const validationGroupDataFields = validationGroup.data_fields.filter(
    dataField =>
      !isEmpty(dataField) &&
      dataField.value !== null &&
      dataField.type !== 'hidden_field' &&
      dataField.type !== 'resume',
  );

  if (!applicantData) {
    return { isLoading: false, dataFields: [] };
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { details } = applicantData;

  const dataFields = dataFieldsMeta
    .map(({ dataType, filter }) =>
      validationGroupDataFields
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        .map(map(details[dataType], dataType))
        .filter(filter),
    )
    .reduce((a, b) => [...a, ...b], []);

  return { dataFields, isLoading: false };
};

export interface QueryStringValues {
  validationGroupId: null | string;
  applicantId: null | string;
  toStageId: null | string;
  warnW4Federal: null | string;
  warnWotc: null | string;
  transitionType: null | string;
  selectedJobId: null | string;
  selectedRunAutomatedActions: null | string;
}

export const useGetQueryStringValues = () => {
  const location = useLocation();
  const {
    validationGroupId,
    applicantId,
    toStageId,
    warnW4Federal,
    warnWotc,
    transitionType,
    selectedJobId,
    selectedRunAutomatedActions,
  } = queryString.parse(location.search);

  return {
    validationGroupId,
    applicantId,
    toStageId,
    warnW4Federal,
    warnWotc,
    transitionType,
    selectedJobId,
    selectedRunAutomatedActions,
  } as QueryStringValues;
};
