import { Loader } from '@fountain/fountain-ui-components';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectAccountSlug } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { deleteAgent } from '../api';
import { messages } from '../messages';
import { AxAgentResponse } from '../types';
import { useDeleteConfirmationStyles } from './styles';

interface DeleteConfirmationModalProps {
  handleClose: () => void;
  currentAgentId: string;
  openConfirmDialog: boolean;
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshToken: React.Dispatch<React.SetStateAction<boolean>>;
  setAgents: React.Dispatch<React.SetStateAction<AxAgentResponse[]>>;
}

export const DeleteConfirmationModal: React.FC<
  DeleteConfirmationModalProps
> = ({
  handleClose,
  currentAgentId,
  openConfirmDialog,
  setOpenConfirmDialog,
  setRefreshToken,
  setAgents,
}) => {
  const classes = useDeleteConfirmationStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';
  const [isLoading, setIsLoading] = useState(false);

  const confirmDelete = async () => {
    setIsLoading(true);
    try {
      await deleteAgent(accountSlug, currentAgentId);
      setAgents(prevAgents =>
        prevAgents.filter(agent => agent.uuid !== currentAgentId),
      );
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.agentDeleteSuccess),
          'success',
        ),
      );
    } catch (err) {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.agentDeleteFailed),
          'error',
        ),
      );
    } finally {
      setIsLoading(false);
      setOpenConfirmDialog(false);
      setRefreshToken(true);
      handleClose();
    }
  };

  if (isLoading) return <Loader fullScreen size="2rem" />;

  return (
    <Dialog
      open={openConfirmDialog}
      onClose={() => setOpenConfirmDialog(false)}
      classes={{ paperScrollPaper: classes.modalContainer }}
    >
      <DialogTitle classes={{ root: classes.modalTitle }}>
        Delete AI agent
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <DialogContentText classes={{ root: classes.modalContentText }}>
          Are you sure you want to delete this AI agent? <br />
          This action can&apos;t be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={() => {
            setOpenConfirmDialog(false);
            handleClose();
          }}
          color="default"
          variant="outlined"
          className={classes.btnCancel}
        >
          Cancel
        </Button>
        <Button
          onClick={() => confirmDelete()}
          color="secondary"
          variant="outlined"
          className={classes.btnDelete}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
