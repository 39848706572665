import { useState } from 'react';
import { useIntl } from 'react-intl';

import { messages } from '../messages';
import { AxAgentValues } from '../types';

interface Errors {
  [key: string]: string;
}

const useValidation = () => {
  const intl = useIntl();
  const [errors, setErrors] = useState<Errors>({});

  const validateFields = (axAgentValues: AxAgentValues): boolean => {
    const newErrors: Errors = {};
    if (!axAgentValues.chatbotName.trim()) {
      newErrors.chatbotName = intl.formatMessage(messages.aiAgentNameRequired);
    }
    if (!axAgentValues.enableInFountainPlatforms && !axAgentValues.smsEnabled) {
      newErrors.visibility = intl.formatMessage(messages.visibilityIsRequired);
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateErrors = (
    name: string,
    newValue: string | boolean,
    axAgentValues: AxAgentValues,
  ): void => {
    setErrors(prevErrors => {
      const newErrors = { ...prevErrors };
      if (name === 'chatbotName' && newValue) {
        delete newErrors.chatbotName;
      }
      if (
        (name === 'enableInFountainPlatforms' || name === 'smsEnabled') &&
        (newValue ||
          axAgentValues.enableInFountainPlatforms ||
          axAgentValues.smsEnabled)
      ) {
        delete newErrors.visibility;
      }
      return newErrors;
    });
  };

  return {
    errors,
    validateFields,
    updateErrors,
  };
};

export default useValidation;
