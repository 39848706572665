import {
  CancelablePromise,
  EventData,
  EventsService,
} from 'api-clients/monolith';
import { useForm } from 'hooks';
import invariant from 'invariant';
import React, { useCallback, useContext, useMemo, useState, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectWhoami } from 'containers/Auth_old/selectors';
import {
  addDefaultErrorMessageActionI18n,
  addMessageAction,
} from 'containers/FlashMessage/actions';
import { getMinDate } from 'containers/ScheduleApplicantModal/components/AddAvailabilityModal/utils';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { AddEventModal, TimeOption } from './AddEventModal';
import { eventManagerContext, EventModalVariant } from './context';
import { messages } from './messages';
import { useGenerateAvailability } from './useGenerateAvailability';

const getHeaderText = (variant: EventModalVariant) => {
  switch (variant) {
    case 'edit':
      return messages.editEvent;
    case 'duplicate':
      return messages.duplicateEvent;
    default:
      return messages.addEvent;
  }
};

export interface AddEventModalContainerProps {
  onClose: () => void;
  refetch: () => void;
}

export const AddEventModalContainer: VFC<AddEventModalContainerProps> = ({
  onClose,
  refetch,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [isDirty, setIsDirty] = useState(false);
  const {
    external_id: externalId,
    time_zone: timeZone,
    locale,
  } = useSelector(makeSelectWhoami());
  const { selectedEvent, eventModalVariant, stageOptions, userOptions } =
    useContext(eventManagerContext);

  invariant(locale, 'locale is not defined');

  const minDate = useMemo(() => getMinDate(timeZone), [timeZone]);

  const validate = (values: Partial<EventData>) => {
    const errors: Partial<Record<keyof EventData, string>> = {};

    if (!values.opening_stage_ids?.length) {
      errors.opening_stage_ids = intl.formatMessage(messages.requiredField);
    }
    if (!values.user) {
      errors.user = intl.formatMessage(messages.requiredField);
    }
    if (!values.start_time) {
      errors.start_time = intl.formatMessage(messages.requiredField);
    }
    if (values.start_time && new Date(values.start_time) < new Date()) {
      errors.start_time = intl.formatMessage(messages.timeErrorMessage);
    }
    if (!values.end_time) {
      errors.end_time = intl.formatMessage(messages.requiredField);
    }
    if (values.end_time && new Date(values.end_time) < new Date()) {
      errors.end_time = intl.formatMessage(messages.timeErrorMessage);
    }

    return errors;
  };

  const setDefaultValues = useCallback(
    (variant: EventModalVariant): EventData => {
      if (variant === 'create') {
        return {
          start_time: '',
          end_time: '',
          max_attendees: '1',
          opening_stage_ids: [],
          location: '',
          instructions: '',
          title: '',
          user:
            userOptions.find(user => user.external_id === externalId)
              ?.external_id ?? '',
        };
      }
      invariant(selectedEvent, 'selectedEvent must exist');
      return {
        start_time: selectedEvent.start_time,
        end_time: selectedEvent.end_time,
        max_attendees: selectedEvent.max_attendees,
        opening_stage_ids: selectedEvent.opening_stage_ids,
        location: selectedEvent.location,
        instructions: selectedEvent.instructions,
        title: selectedEvent.title,
        user: selectedEvent.user_id ?? '',
      };
    },
    [externalId, selectedEvent, userOptions],
  );

  const headerText = getHeaderText(eventModalVariant);
  const defaultFormValue = useMemo(
    () => setDefaultValues(eventModalVariant),
    [eventModalVariant, setDefaultValues],
  );

  const { mutation: createEvent, result } = useApiServiceMutation<
    // eslint-disable-next-line camelcase
    { external_id: string },
    ({
      // eslint-disable-next-line camelcase
      user_external_id,
      event,
    }: {
      // eslint-disable-next-line camelcase
      user_external_id: string;
      event: EventData;
      // eslint-disable-next-line camelcase
    }) => CancelablePromise<{ external_id: string }>
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(EventsService.postInternalApiEvents, {
    onSuccess: () => {
      refetch();
      onClose();
      dispatch(
        addMessageAction(intl.formatMessage(messages.deleteSuccess), 'success'),
      );
    },
    onError: () => {
      dispatch(addDefaultErrorMessageActionI18n(intl));
    },
  });

  const onSubmit = (values: EventData) => {
    createEvent({
      user_external_id: values.user,
      event: values,
    });
  };

  const { handleChange, handleSubmit, values, errors } = useForm<EventData>(
    onSubmit,
    validate,
    defaultFormValue,
  );

  const {
    selectedDate,
    availableEndTimes,
    availableStartTimes,
    onStartDateChange,
    setSelectedDate,
  } = useGenerateAvailability({
    minDate,
    handleChange,
    defaultStart: selectedEvent?.start_time,
    defaultEnd: selectedEvent?.end_time,
  });

  const onChange = (params: Partial<EventData>) => {
    setIsDirty(true);
    handleChange(params);
  };

  const handleStartDateChange = (timeOption: TimeOption) => {
    setIsDirty(true);
    onStartDateChange(timeOption);
  };

  const handleDateChange = (date: Date) => {
    setIsDirty(true);
    setSelectedDate(date);
  };

  return (
    <AddEventModal
      errors={errors}
      locale={locale}
      selectedDate={selectedDate}
      minDate={minDate}
      onDateChange={handleDateChange}
      onChange={onChange}
      handleSubmit={handleSubmit}
      handleClose={onClose}
      payload={values}
      openingStageOptions={stageOptions}
      startTimeOptions={availableStartTimes}
      endTimeOptions={availableEndTimes}
      userOptions={userOptions}
      timeZone={timeZone}
      onStartDateChange={handleStartDateChange}
      isLoading={result.isLoading}
      isDirty={isDirty}
      headerText={intl.formatMessage(headerText)}
    />
  );
};
