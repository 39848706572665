import { theme } from '@fountain/fountain-ui-components';
import { useMediaQuery } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route as PublicRoute,
  Switch,
  useLocation,
} from 'react-router-dom';

import RootContainer from 'components/RootContainer/Loadable';
import Account from 'containers/Account';
import ApplicantTableStyle from 'containers/App/ApplicantTableStyle';
import AppGlobalStyle from 'containers/App/GlobalStyle';
import {
  makeSelectAccountSlug,
  makeSelectFirstJobId,
  makeSelectIsFetchingWhoami,
  makeSelectIsLoggedIn,
} from 'containers/Auth_old/selectors';
import AuthRoute from 'containers/AuthRoute';
import CCPA from 'containers/CCPA';
import FlashMessage from 'containers/FlashMessage';
import LandingPage from 'containers/LandingPage';
import NotFoundPage from 'containers/NotFoundPage/Loadable';

export default function App() {
  const location = useLocation();

  const { pathname } = location;

  const isLoggedIn = useSelector(makeSelectIsLoggedIn());
  const isLoading = useSelector(makeSelectIsFetchingWhoami());
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const accountSlug = useSelector(makeSelectAccountSlug());
  const jobId = useSelector(makeSelectFirstJobId());
  const applicantTableV2Url = `/${accountSlug}/jobs/${jobId}/v2/stages?page=1`;

  const getRedirectUrl = () => {
    if (mobileDevice) return applicantTableV2Url;

    return `/${accountSlug}/openings`;
  };

  useEffect(() => {
    if (window.Appcues) {
      window.Appcues.page();
    }
  }, [pathname]);

  return (
    <RootContainer>
      <Helmet titleTemplate="%s | Fountain" defaultTitle="Talent at Fountain">
        <meta name="description" content="Find talent at Fountain" />
      </Helmet>
      <Switch>
        <PublicRoute path="/landing" component={LandingPage} />
        <PublicRoute path="/ccpa" component={CCPA} />

        {/* Handle redirect from '/:accountSlug' */}
        {!isLoading && isLoggedIn && (
          <Redirect from="/:accountSlug" exact to={getRedirectUrl()} />
        )}
        {/* Handles all authenticated routes under /:accountSlug */}
        <AuthRoute path="/:accountSlug" component={Account} />
        {/* Handle redirect from '/'
            Let LandingPage handle redirect to applicantTableV2Url */}
        <Redirect from="/" exact to="/landing" />
        <PublicRoute component={NotFoundPage} />
      </Switch>
      <AppGlobalStyle />
      <ApplicantTableStyle theme={theme} />
      <FlashMessage />
    </RootContainer>
  );
}
