import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, VFC } from 'react';
import { classNames } from 'react-extras';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { makeSelectAccountSlug } from 'containers/Auth_old/selectors';
import { addMessageAction } from 'containers/FlashMessage/actions';

import {
  createAgent,
  PublishChatAgent,
  updateAtsConnection,
  updateAxAgent,
} from '../api';
import { initialAxAgentValues, steps } from '../constants';
import { messages } from '../messages';
import { AgentStatus, AxAgentValues } from '../types';
import { useFooterStyles } from './styles';

export interface FooterProps {
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  axAgentValues: AxAgentValues;
  accessToken: string;
  setStartChatbotProgressor?: React.Dispatch<React.SetStateAction<boolean>>;
  setAxAgentValues: React.Dispatch<React.SetStateAction<AxAgentValues>>;
  validateFields?: (axAgentValues: AxAgentValues) => boolean;
}

export const Footer: VFC<FooterProps> = ({
  currentStep,
  setCurrentStep,
  axAgentValues,
  accessToken,
  setStartChatbotProgressor,
  setAxAgentValues,
  validateFields,
}) => {
  const classes = useFooterStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingAction, setLoadingAction] = useState<AgentStatus>();
  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';

  const nextStep = async () => {
    if (currentStep === 1 && !axAgentValues.knowledgeBaseUuid) return;
    if (currentStep === 2 && validateFields && !validateFields(axAgentValues))
      return;
    if (currentStep === 2 && !axAgentValues.axAgentId) {
      setIsLoading(true);
      try {
        const agentResponse = await createAgent(
          accountSlug,
          axAgentValues.chatbotName,
          axAgentValues.emailFallback,
          axAgentValues.phoneNumberFallback,
          axAgentValues.websiteFallback,
          axAgentValues.knowledgeBaseUuid,
          axAgentValues.enableInFountainPlatforms,
          axAgentValues.smsEnabled,
        );
        setAxAgentValues(prev => ({
          ...prev,
          axAgentId: agentResponse.agent_id,
          totalChatAgents: prev.totalChatAgents + 1,
        }));
      } catch (err) {
        dispatch(
          addMessageAction(
            intl.formatMessage(messages.agentCreationFailed),
            'error',
          ),
        );
      } finally {
        setIsLoading(false);
      }
    }
    setCurrentStep(currentStep + 1);
  };

  const prevStep = (): void => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const saveOrPublishAgent = async (status: AgentStatus) => {
    setLoadingAction(status);
    setIsLoading(true);
    try {
      await updateAxAgent(accessToken, axAgentValues.axAgentId, {
        brand: axAgentValues.brandName,
        name: axAgentValues.chatbotName,
        emailFallback: axAgentValues.emailFallback,
        phoneNumberFallback: axAgentValues.phoneNumberFallback,
        websiteFallback: axAgentValues.websiteFallback,
        status,
      });

      await PublishChatAgent(
        accountSlug,
        axAgentValues.brandId,
        axAgentValues.enableInFountainPlatforms,
        axAgentValues.smsEnabled,
        axAgentValues.axAgentId,
      );

      await updateAtsConnection(
        accessToken,
        axAgentValues.atsConnectionId,
        axAgentValues.brandName,
        axAgentValues.brandId,
        accountSlug,
      );

      if (setAxAgentValues) setAxAgentValues(initialAxAgentValues);

      dispatch(
        addMessageAction(
          intl.formatMessage(
            status === 'active'
              ? messages.agentPublishSuccess
              : messages.agentSaveSuccess,
          ),
          'success',
        ),
      );
      if (setStartChatbotProgressor) {
        setStartChatbotProgressor(false);
      }
    } catch (err) {
      dispatch(
        addMessageAction(
          intl.formatMessage(
            status === 'active'
              ? messages.agentPublishFailed
              : messages.agentSaveFailed,
          ),
          'error',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  };

  const buttonLabel = () => {
    switch (currentStep) {
      case 1:
        return 'Customize AI agent';
      case 2:
        return 'Customize widget appearance';
      default:
        return '';
    }
  };

  return (
    <div
      className={classNames(classes.navigationButtons, {
        [classes.lastStepNavigationButtons]: currentStep === steps.length,
        [classes.draftNavigationButtons]:
          currentStep === steps.length && axAgentValues.status === 'draft',
      })}
    >
      {currentStep > 1 && (
        <Button
          variant="contained"
          size="small"
          type="button"
          color="default"
          onClick={prevStep}
          disabled={isLoading}
          className={classNames(classes.button, classes.previousBtn)}
        >
          Previous
        </Button>
      )}
      {currentStep === steps.length ? (
        <div style={{ display: 'flex', gap: '16px' }}>
          {axAgentValues.status === 'draft' && (
            <Button
              variant="contained"
              size="small"
              type="button"
              color="primary"
              onClick={() => saveOrPublishAgent('draft')}
              disabled={isLoading}
              className={classNames(classes.button, classes.draftButton)}
            >
              {loadingAction === 'draft' && (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ position: 'absolute' }}
                />
              )}
              <span style={{ opacity: loadingAction === 'draft' ? 0 : 1 }}>
                Save as draft
              </span>
            </Button>
          )}

          <Button
            variant="contained"
            size="small"
            type="button"
            color="primary"
            onClick={() => saveOrPublishAgent('active')}
            disabled={isLoading}
            className={classNames(classes.button, classes.submitButton)}
          >
            {loadingAction === 'active' && (
              <CircularProgress
                size={20}
                color="inherit"
                style={{ position: 'absolute' }}
              />
            )}
            <span style={{ opacity: loadingAction === 'active' ? 0 : 1 }}>
              {axAgentValues.status === 'draft' ? 'Turn on AI agent' : 'Save'}
            </span>
          </Button>
        </div>
      ) : (
        <Button
          variant="contained"
          size="small"
          type="button"
          color="primary"
          onClick={nextStep}
          disabled={isLoading}
          className={classes.button}
        >
          {isLoading && (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ position: 'absolute' }}
            />
          )}
          <span style={{ opacity: isLoading ? 0 : 1 }}>
            Next: {buttonLabel()}
          </span>
        </Button>
      )}
    </div>
  );
};
