import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.addStage',
    defaultMessage: 'Add Stage',
  },
  backgroundCheckerStageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.backgroundCheckerStageDescription',
    defaultMessage:
      'A background check stage requires an integration with a preferred vendor. Once the integration is set up, the stage is broken up into two components, one of which is required:{br}{br} Automated Message - Applicants will receive a message including a link to a background check form. Upon consenting to the background check, you can either automatically run the background check or not.{br}{br} Trigger Message - After an applicant has not consented to a background check for a set amount of days, auto-send a Trigger Message (you can set multiple).',
  },
  cancel: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.cancel',
    defaultMessage: 'Cancel',
  },
  customStageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.customStageDescription',
    defaultMessage:
      'After an applicant lands on a manual stage, you have the option of setting an Automated Message to send to the applicant. If no Automated Message is set, nothing happens.',
  },
  dataCollectionStageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.dataCollectionStageDescription',
    defaultMessage:
      'Upon landing on a Data Collection stage, applicants will receive a message with a link asking them to complete a form (and upload files/answer questions). This information will be securely appended to their applicant profile.',
  },
  documentSignatureStageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.documentSignatureStageDescription',
    defaultMessage:
      'Once an integration is complete with HelloSign or DocuSign API accounts, Document Signing stages allows Fountain to automatically send a HelloSign or DocuSign template to applicants to sign upon landing on a stage.',
  },
  jobSelectorStageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.jobSelectorStageDescription',
    defaultMessage:
      'Upon landing on a Job Selector stage, applicants will have the choice to select one opening from a list of active, hiring openings that match conditions configured on this stage. Once they select an opening, they will self transition to the first stage of that opening, or to the next stage if they select the opening they are currently in.',
  },
  jobSwitcherStageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.jobSwitcherStageDescription',
    defaultMessage:
      'Job Switcher stages appear to applicants when an opening is inactive. Upon landing, applicants will have the choice to select one opening from a list of active, hiring openings that use the same workflow, and match conditions configured on this stage. Once they select an opening, they will self transition to the next stage in that opening, maintaining previous progress. If an opening is active, applicants will always skip over the Job Switcher stage. Requires 1:Many Workflows to function.',
  },
  header: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.header',
    defaultMessage: 'Create New Stage',
  },
  schedulerStageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.schedulerStageDescription',
    defaultMessage:
      'A scheduler stage is broken up into three components, two of which are required: {br}{br}Scheduler Message - When an applicant lands, Scheduler Message is sent containing available sessions created in the Fountain Calendar.{br}{br} Confirmation Message - Once an applicant books a session from the Scheduler Message, a Confirmation Message is sent.',
  },
  schedulerV2StageDescription: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.schedulerV2StageDescription',
    defaultMessage:
      'A scheduler stage is broken up into three components, two of which are required: {br}{br}Scheduler Message - When an applicant lands, Scheduler Message is sent containing a link to book an interview.{br}{br} Confirmation Message - Once an applicant books a session from the Scheduler Message, a Confirmation Message is sent.',
  },
  selectAStage: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.selectAStage',
    defaultMessage: 'Select a stage',
  },
  selectAStageType: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.selectAStageType',
    defaultMessage: 'Select a stage type',
  },
  selectAState: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.selectAState',
    defaultMessage: 'Select a state',
  },
  stageLoadError: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.stageLoadError',
    defaultMessage: 'Failed to load available stages, please try again.',
  },
  stagePlacement: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.stagePlacement',
    defaultMessage: 'Stage Placement',
  },
  stageRequired: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.stageRequired',
    defaultMessage: 'Add a stage title',
  },
  stageSuccess: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.stageSuccess',
    defaultMessage: 'Stage created successfully',
  },
  stageType: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.stageType',
    defaultMessage: 'Stage Type',
  },
  state: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.state',
    defaultMessage: 'State',
  },
  title: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.title',
    defaultMessage: 'Title',
  },
  w4StateFormNotice: {
    id: 'app.containers.WorkflowEditor.components.StageColumnHeader.NewStageModal.w4StateFormNotice',
    defaultMessage:
      'Note that you cannot create a W-4 State form for the following states: ND, NM, UT. These states uses the W-4 Federal form for Employee Withholding and you should select this stage type instead.',
  },
});
