import { FunnelDetails, WorkflowMultiStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { AdvancedSettingsCard } from './AdvancedSettingsCard';
import { SubstagesCard } from './SubstagesCard';

export interface MultiStageProps {
  opening: FunnelDetails;
  stage: WorkflowMultiStage;
}

export const MultiStage: VFC<MultiStageProps> = ({ opening, stage }) => {
  return (
    <div data-testid="multiStage">
      <StageSection>
        <SubstagesCard opening={opening} stage={stage} />
      </StageSection>
      <StageSection>
        <AdvancedSettingsCard stage={stage} />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="multi_stage"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
    </div>
  );
};
