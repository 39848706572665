/* eslint-disable camelcase */
import {
  AvailableStageType,
  ConditionSetAttributes,
  FunnelDetails,
  ReasonSetting,
  ReminderSetting,
  SidebarStage,
  TriggerSetting,
  WorkflowBaseStage,
} from 'api-clients/monolith';

import { mockPartner2 } from 'containers/WorkflowEditor/components/Rules/Rules.fixtures';
import { faker } from 'testUtils';

export const mockMessageTemplate = {
  delay_minutes: faker.datatype.number(),
  delivery_disabled: false,
  email_enabled: true,
  id: faker.datatype.number(),
  modified_at: '',
  modified_by_user_name: faker.name.firstName(),
  name: faker.name.jobTitle(),
  sms_enabled: false,
  template_id: faker.datatype.number(),
};

export const mockReasonSetting: ReasonSetting = {
  id: faker.datatype.number(),
  delay_hours: faker.datatype.number(),
  reason_id: faker.datatype.number(),
  stage_id: faker.datatype.number(),
  type: 'StageSettings::Archivation',
  message_template: mockMessageTemplate,
};

export const mockReminderSetting: ReminderSetting = {
  id: faker.datatype.number(),
  minutes_to_notify: faker.datatype.number(),
  stage_id: faker.datatype.number(),
  message_template: mockMessageTemplate,
};

export const mockTriggerReasonSetting: TriggerSetting = {
  id: faker.datatype.number(),
  idle_days: faker.datatype.number(),
  stage_id: faker.datatype.number(),
  disabled: false,
  message_template: mockMessageTemplate,
};

export function genMockTriggerReasonSetting({
  id = faker.datatype.number(),
  idle_days = faker.datatype.number(),
  stage_id = faker.datatype.number(),
  disabled = false,
  message_template = mockMessageTemplate,
}: Partial<TriggerSetting>): TriggerSetting {
  return {
    id,
    idle_days,
    stage_id,
    disabled,
    message_template,
  };
}

export const mockMoveRule = {
  enable_move_to_notifications: false,
  id: faker.datatype.number(),
  idle_days_count: faker.datatype.number(),
  ignore_applicant_action: false,
  target_stage_id: faker.datatype.number(),
  target_stage_title: faker.hacker.noun(),
};

export const mockRestrictedLocation = {
  id: faker.datatype.number(),
  location: faker.name.jobArea(),
};

export const mockAction = {
  id: 107,
  action_set_id: 60,
  action_set_type: 'RuleStage::RuleActionSet',
  extra: {},
  ref_else_action_set_id: null,
  ref_rule_action_set_id: 60,
  ref_stage_1_id: null,
  rejection_reason_id: null,
  archived_reason_id: null,
  type: 'RuleStage::Actions::MoveToNextStage',
};

export const mockCondition = {
  id: 114,
  condition_set_id: 63,
  extra: {
    key: 'bro',
    partner_id: mockPartner2.value,
    partner_option: 'ftn_rules_any',
  },
  ref_stage_1_id: null,
  ref_score_card_template_id: null,
  type: 'ApplicantLogic::Conditions::PartnerDetail::HasKey',
};

const mockConditionSetAttributes: ConditionSetAttributes = {
  id: 63,
  conditionable_id: 62,
  conditionable_type: 'RuleStage::Rule',
  logic: 'AND',
  ref_document_signature_template_rule_id: null,
  ref_document_signing_template_rule_id: null,
  ref_rule_id: 62,
  conditions_attributes: [mockCondition],
};

export const mockRule = {
  id: 62,
  action_set_attributes: {
    id: 60,
    actions_attributes: [mockAction],
  },
  condition_set_attributes: mockConditionSetAttributes,
};

export const mockElseActionSetAttributes = {
  id: 100,
  actions_attributes: [mockAction],
};

export const mockRuleSetAttributes = {
  id: 55,
  rules_attributes: [mockRule],
};

export function genMockBaseStage({
  title = 'Test',
  id = faker.datatype.number(),
  external_id = faker.datatype.uuid(),
  slug = 'mockSlug',
  extra = {},
  advanced_settings = {
    is_hidden_from_portal: false,
    should_applicant_self_advance: null,
    should_auto_advance_when_labels_complete: false,
  },
  position = 0,
}: Partial<WorkflowBaseStage> = {}): WorkflowBaseStage {
  return {
    title,
    id,
    external_id,
    extra,
    advanced_settings,
    slug,
    position,
    portal_body: null,
    portal_title: faker.word.noun(),
    portal_instructions: faker.lorem.sentence(),
    landing_template: undefined,
    is_rule_stage: false,
    has_success_message: false,
    has_stage_labels: false,
  };
}

interface StagesResponseData {
  hiring_goal: number | null;
  unsupported_stage_types: string[];
  stages: Array<SidebarStage>;
}

export const mockStages: StagesResponseData = {
  hiring_goal: null,
  unsupported_stage_types: [],
  stages: [
    {
      id: 1,
      title: 'test advanced',
      type: 'FountainDocumentSigningStage',
      applicants_count: 0,
      move_rule: null,
      short_type: 'Signature',
      external_id: 'a458ee82-3a65-4c46-8a26-4297d7d7ae11',
      position: 1,
      additional_info: null,
      slug: 'test_advanced',
      extra: {},
    },
    {
      id: 2,
      title: 'Landing',
      type: 'CustomStage',
      applicants_count: 109,
      move_rule: null,
      short_type: 'Custom',
      external_id: 'aadf9445-0fad-4131-83a0-1a4221cde273',
      position: 2,
      additional_info: null,
      slug: 'landing',
      extra: {},
    },
    {
      id: 3,
      title: 'In-person Interview',
      type: 'SchedulerStage',
      applicants_count: 7,
      move_rule: null,
      short_type: 'Scheduler',
      external_id: '6ca6757f-cce9-4b75-8355-d50688e32bbe',
      position: 3,
      additional_info: null,
      slug: 'in-person-interview',
      extra: {},
    },
    {
      id: 4,
      title: 'Background Check',
      type: 'BackgroundCheckerStage',
      applicants_count: 6,
      move_rule: null,
      short_type: 'Background Check',
      external_id: '1019eb4d-bce6-4c00-b3b6-69f0ec2dcfc9',
      position: 4,
      additional_info: {
        is_integration_configured: true,
        package_name: 'Driver Pro',
      },
      slug: 'background-check',
      extra: {},
    },
    {
      id: 5,
      title: 'Document upload',
      type: 'DataCollectionStage',
      applicants_count: 12,
      move_rule: null,
      short_type: 'Data',
      external_id: 'f8ee0bb7-4c4f-4549-b0db-36835a1393f4',
      position: 5,
      additional_info: null,
      slug: 'document-upload',
      extra: {},
    },
    {
      id: 6,
      title: 'Approved',
      type: 'HiredStage',
      applicants_count: 6,
      move_rule: null,
      short_type: 'Approved',
      external_id: 'a7cfbc8d-3a1d-4a58-9923-3b9cb4444e5b',
      position: 6,
      additional_info: null,
      slug: 'approved',
      extra: {},
    },
    {
      id: 7,
      title: 'Rejected',
      type: 'RejectedStage',
      applicants_count: 11,
      move_rule: null,
      short_type: 'Rejected',
      external_id: '4eca1558-506a-4f16-801b-c944c10414a9',
      position: 7,
      additional_info: null,
      slug: 'rejected',
      extra: {},
    },
    {
      id: 8,
      title: 'On Hold',
      type: 'ArchivedStage',
      applicants_count: 11,
      move_rule: null,
      short_type: 'On Hold',
      external_id: '8894c288-ac74-4a72-8ae3-ed0a3171a775',
      position: 8,
      additional_info: null,
      slug: 'on-hold',
      extra: {},
    },
  ],
};

interface MockAvailableStagesResponseData {
  stage_types: AvailableStageType[];
}

export const mockAvailableStages: MockAvailableStagesResponseData = {
  stage_types: [
    {
      stage_type_limit_reached: false,
      name: 'Background Check with Checkr (USA)',
      type: 'BackgroundCheckerStage',
    },
    {
      stage_type_limit_reached: false,
      name: 'Background Check with Onfido',
      type: 'OnfidoStage',
    },
    { stage_type_limit_reached: false, name: 'Custom', type: 'CustomStage' },
    {
      stage_type_limit_reached: false,
      name: 'Data Collection',
      type: 'DataCollectionStage',
    },
    {
      stage_type_limit_reached: false,
      name: 'Document Signing',
      type: 'DocumentSigningStage',
    },
    { stage_type_limit_reached: false, name: 'I-9 Form', type: 'I9FormStage' },
    {
      stage_type_limit_reached: false,
      name: 'Multi Stage',
      type: 'MultiStage',
    },
    {
      stage_type_limit_reached: false,
      name: 'Scheduler',
      type: 'SchedulerStage',
    },
    {
      stage_type_limit_reached: false,
      name: 'Video Recording',
      type: 'VideoRecordingStage',
    },
    {
      stage_type_limit_reached: false,
      name: 'W4 Federal Form',
      type: 'W4FederalFormStage',
    },
    {
      stage_type_limit_reached: false,
      name: 'W4 State Form',
      type: 'W4StateFormStage',
    },
  ],
};

export const mockOpening: FunnelDetails = {
  applicants_count: 0,
  title: 'mockOpening',
  slug: 'mockSlug',
  external_id: 'mockId',
  manage_labels: false,
  location: {
    address: '',
  },
  is_workflow_enabled_funnel: false,
  workflow: null,
  workflow_funnels: [],
};

export const mockOpeningWithWorkflow: FunnelDetails = {
  applicants_count: 0,
  title: 'mockOpening',
  slug: 'mockSlug',
  external_id: 'mockId',
  manage_labels: false,
  location: {
    address: '',
  },
  is_workflow_enabled_funnel: false,
  workflow: {
    external_id: 'mockWorkflowID',
    id: 21,
    title: 'mockWorkflow',
  },
  workflow_funnels: [{ id: 4, slug: 'mockSlug', title: 'mockOpening' }],
};
