import {
  StyledReactSelect,
  styledSelectDefaultStyles,
} from '@fountain/fountain-ui-components';
import { Grid } from '@material-ui/core';
import { User, WorkflowCustomStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import {
  AutoAdvanceWhenLabelsComplete,
  IsHiddenFromPortal,
} from '../../SettingsSwitches';
import { messages } from './messages';
import { useStyles } from './styles';

export const AdvancedSettings: VFC<{ stage: WorkflowCustomStage }> = ({
  stage,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { setStage } = useContext(StageContext);

  const isSubstage = Boolean(stage.parent_stage_id);
  const hasStageLabels = Boolean(stage.has_stage_labels);
  const emailRecipientUserOptions = stage.additional_info.user_options;
  const accountOwner = emailRecipientUserOptions.find(({ owner }) => owner);
  const fallbackUser = stage.additional_info.user_options[0];
  const selectStyles = {
    ...styledSelectDefaultStyles,
    menuList: (provided: Record<string, unknown>) => ({
      ...provided,
      maxHeight: '160px',
    }),
  };

  const onToggleChange =
    (key: keyof WorkflowCustomStage['advanced_settings']) => () => {
      setStage(
        produce(stage, draftStage => {
          draftStage.advanced_settings[key] =
            !draftStage.advanced_settings[key];
        }),
      );
    };

  const onToggleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = Boolean(event.target.checked);
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.email_user =
          checked === false ? null : accountOwner?.email ?? fallbackUser?.email;
      }),
    );
  };

  const onChangeEmailRecipient = (recipient: User) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.extra.email_user = recipient.email;
      }),
    );
  };

  const isEmailChecked = Boolean(stage.extra.email_user);
  const labelForEmailDropdown = (user: User) => {
    if (user.email === accountOwner?.email) {
      return intl.formatMessage(messages.accountOwner);
    }
    return `${user.name} (${user.email})`;
  };

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.advancedSettings)}
    >
      <Grid className={styles.cardBody}>
        {!isSubstage && (
          <IsHiddenFromPortal stage={stage} setStage={setStage} />
        )}
        <SettingSwitch
          dataKey="allow_applicants_to_advance"
          disabled={isSubstage}
          checked={
            stage.advanced_settings.should_applicant_self_advance ?? false
          }
          onChange={onToggleChange('should_applicant_self_advance')}
          label={<FormattedMessage {...messages.allowApplicantsToAdvance} />}
        />
        {!isSubstage && hasStageLabels && (
          <AutoAdvanceWhenLabelsComplete stage={stage} setStage={setStage} />
        )}
        {!isSubstage && (
          <SettingSwitch
            dataKey="send_email"
            checked={isEmailChecked}
            onChange={onToggleEmail}
            label={<FormattedMessage {...messages.sendAnEmail} />}
            fieldComponent={
              isEmailChecked ? (
                <StyledReactSelect
                  aria-label={intl.formatMessage(messages.recipient)}
                  className={styles.select}
                  getOptionLabel={labelForEmailDropdown}
                  getOptionValue={(user: User) => user.email}
                  label={
                    <>
                      <FormattedMessage {...messages.recipient} />{' '}
                      <span className={styles.requiredAsterix}>*</span>
                    </>
                  }
                  onChange={onChangeEmailRecipient}
                  options={emailRecipientUserOptions}
                  value={emailRecipientUserOptions.find(({ email }) => {
                    return email === stage.extra.email_user;
                  })}
                  styles={selectStyles}
                />
              ) : null
            }
          />
        )}
      </Grid>
    </StageSettingCard>
  );
};
