export const APPLICANT_DRAWER_TABS = {
  GENERAL: 0, // Formerly referred to as INFO
  MESSAGES: 1,
  EMAILS: 2,
  HISTORY: 3,
  FILES: 4,
};

export const LEFT_COLUMN_MAX_WIDTH = 568;
export const RIGHT_COLUMN_WIDTH = 480;

export const TAB_PANEL_STYLES: Pick<
  React.CSSProperties,
  'display' | 'flexDirection' | 'overflowY'
> = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'hidden',
};
