import {
  Button,
  InnerContent,
  SimpleModalFooter,
} from '@fountain/fountain-ui-components';
import { makeStyles } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useResubmitPartnerOptionData from 'hooks/partners/useResubmitPartnerOptionData';
import globalMessages from 'shared/global/messages';

import { messages } from '../../messages';
import EditPartnerOptionDataForm from '../EditPartnerOptionDataForm';

const useStyles = makeStyles(theme => ({
  cancelButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
    },
  },
  formControlLabel: {
    color: theme.palette.common.black,
    padding: theme.spacing(0.25),
  },
  radioGroup: {
    marginTop: theme.spacing(2),
  },
  radioText: {
    fontSize: theme.typography.fontSize,
  },
}));

interface ModalContentProps {
  handleClose: () => void;
  applicantId: string;
  stageId: string;
  partnerId: string;
  partnerOptionId: string;
  partnerStatusId?: string;
}

export const EditPartnerOptionDataModalContent: VFC<ModalContentProps> = ({
  handleClose,
  applicantId,
  stageId,
  partnerId,
  partnerOptionId,
  partnerStatusId,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { resubmitPartnerOptionData, isSubmitting } =
    useResubmitPartnerOptionData({
      successMessage: intl.formatMessage(messages.resubmitFieldSuccessMessage),
      errorMessage: intl.formatMessage(messages.resubmitFieldErrorMessage),
    });

  const onSubmit = () => {
    void resubmitPartnerOptionData({
      applicantId,
      partnerId,
      partnerStageId: stageId,
    });
  };

  return (
    <>
      <InnerContent>
        <EditPartnerOptionDataForm
          applicantId={applicantId}
          stageId={stageId}
          partnerOptionId={partnerOptionId}
          partnerStatusId={partnerStatusId}
        />
      </InnerContent>
      <SimpleModalFooter>
        <Button
          className={classes.cancelButton}
          type="secondary"
          onClick={handleClose}
          size="small"
        >
          <FormattedMessage {...globalMessages.cancel} />
        </Button>
        <Button
          className={classes.submitButton}
          onClick={onSubmit}
          size="small"
          disabled={isSubmitting}
        >
          <FormattedMessage {...messages.resubmitButton} />
        </Button>
      </SimpleModalFooter>
    </>
  );
};

export default EditPartnerOptionDataModalContent;
