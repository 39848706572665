import { Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import cx from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { messages } from '../messages';
import { useStyles } from '../styles';
import { Filterable } from '../util';
import Close from './close.svg';
import Down from './down.svg';

export interface FilterDropdownButtonProps {
  error?: boolean;
  name: 'opening' | 'stage' | 'locationGroup';
  open: boolean;
  openUp: () => void;
  count: number;
  clearFilter: () => void;
  titles: string[];
  showTitle?: boolean;
  setSelected?: (selected: Filterable[]) => void;
  selected?: Filterable[];
}

export const FilterDropdownButton: FC<FilterDropdownButtonProps> = ({
  name,
  open,
  openUp,
  count = 0,
  clearFilter,
  titles,
  showTitle,
  setSelected,
  selected,
  error = false,
}) => {
  const classes = useStyles();

  const updateSelectedTitles = (title: string) => {
    if (setSelected) {
      const selectedValue =
        selected && selected.filter(item => item.title !== title);
      setSelected(selectedValue ?? []);
    }
  };

  const openpill = showTitle ? (
    <>
      {titles.map(title => (
        <Grid item className={classes.rounded} key={title}>
          <Typography className={classes.centered}>
            {title}

            <button
              onClick={() => updateSelectedTitles(title)}
              type="button"
              className={classes.invisibleButton}
            >
              <img src={Close} alt={`clear ${name}`} />{' '}
            </button>
          </Typography>
        </Grid>
      ))}
    </>
  ) : open ? (
    <Grid item className={classes.rounded}>
      <FormattedMessage {...messages[name]} values={{ count }} />
      <button
        onClick={clearFilter}
        type="button"
        className={classes.invisibleButton}
      >
        <img src={Close} alt={`clear ${name}`} />{' '}
      </button>
    </Grid>
  ) : (
    <Tooltip
      title={titles.map(title => (
        <Typography className={classes.smallTitle} key={title}>
          {title}
        </Typography>
      ))}
    >
      <Grid item className={classes.rounded}>
        <FormattedMessage {...messages[name]} values={{ count }} />
        <button
          onClick={clearFilter}
          type="button"
          className={classes.invisibleButton}
        >
          <img src={Close} alt={`clear ${name}`} />
        </button>
      </Grid>
    </Tooltip>
  );
  const pill =
    count > 0 ? (
      <>
        {openpill}
        <Grid
          item
          className={classes.spacing}
          onClick={openUp}
          onKeyDown={openUp}
        ></Grid>
      </>
    ) : (
      <Grid
        item
        className={classes.spacing}
        onClick={openUp}
        onKeyDown={openUp}
      >
        <FormattedMessage {...messages[name]} values={{ count }} />
      </Grid>
    );

  return (
    <Paper
      className={cx(
        error && classes.errorButton,
        open ? classes.openButton : classes.closedButton,
      )}
      elevation={0}
    >
      <Grid className={classes.buttonInnerContainer} id={`filter-${name}-btn`}>
        {pill}
        <Grid onClick={openUp}>
          <img
            src={Down}
            alt="open"
            className={classes[open ? 'openCaret' : 'closedCaret']}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
