import { Loader } from '@fountain/fountain-ui-components';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  makeSelectAccountId,
  makeSelectAccountSlug,
} from 'containers/Auth_old/selectors';
import { AxAgentResponse, AxAgentValues } from 'containers/ChatAgent/types';
import { addMessageAction } from 'containers/FlashMessage/actions';

import { fetchAccessToken, fetchAgentConnections, listAxAgents } from '../api';
import { initialAxAgentValues } from '../constants';
import { messages } from '../messages';
import { AgentList } from './AgentList';
import { ChatbotProgressor } from './ChatbotProgressor';
import { SetupInstructions } from './SetupInstructions';

export const ChatbotSetup = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [refreshToken, setRefreshToken] = useState(false);
  const [startChatbotProgressor, setStartChatbotProgressor] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [agents, setAgents] = useState<AxAgentResponse[]>([]);
  const [axAgentValues, setAxAgentValues] =
    useState<AxAgentValues>(initialAxAgentValues);

  const accountSlug = useSelector(makeSelectAccountSlug()) ?? '';
  const accountExternalId = useSelector(makeSelectAccountId());

  const tokenKey = `${accountExternalId}-token`;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let storedToken = localStorage.getItem(tokenKey);
        const chatAgentUrl = localStorage.getItem('chatAgentBackendUrl');
        const chatAgentWidgetUrl = localStorage.getItem('chatAgentWidgetUrl');

        if (!storedToken || !chatAgentUrl || !chatAgentWidgetUrl) {
          const data = await fetchAccessToken(accountSlug);
          storedToken = data.access_token;
          localStorage.setItem(tokenKey, storedToken);
          localStorage.setItem(
            'chatAgentBackendUrl',
            data.chat_agent_connection_url,
          );
          localStorage.setItem(
            'chatAgentWidgetUrl',
            data.chat_agent_widget_url,
          );
        }

        setAccessToken(storedToken);

        const agentsData = await listAxAgents(storedToken);
        setAgents(agentsData);

        const data = await fetchAgentConnections(accountSlug);
        setAxAgentValues(prev => ({
          ...prev,
          isBrandsAvailable: data.is_brands_available,
          sampleKnowledgeBaseFileUrl: data.sample_knowledge_base_file_url,
          knowledgeBaseHelpCenterArticleUrl:
            data.knowledge_base_help_center_article_url,
          totalChatAgents: agentsData.length,
          isAllBrandSetup: data.is_all_brand_setup,
        }));
      } catch (err) {
        const error = err as Error & { status?: number };
        if (error.status === 401 || error.status === 400) {
          localStorage.removeItem(tokenKey);
          setRefreshToken(prev => !prev);
        } else {
          dispatch(
            addMessageAction(
              intl.formatMessage(messages.fetchAccessTokenFailed),
              'error',
            ),
          );
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (!startChatbotProgressor) void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountExternalId,
    accountSlug,
    refreshToken,
    startChatbotProgressor,
    agents.length,
  ]);

  if (isLoading) return <Loader fullScreen size="2rem" />;

  if (startChatbotProgressor) {
    return (
      <ChatbotProgressor
        accessToken={accessToken}
        setStartChatbotProgressor={setStartChatbotProgressor}
        setAxAgentValues={setAxAgentValues}
        axAgentValues={axAgentValues}
      />
    );
  }

  return (
    <>
      {agents.length > 0 ? (
        <AgentList
          setStartChatbotProgressor={setStartChatbotProgressor}
          setRefreshToken={setRefreshToken}
          setAxAgentValues={setAxAgentValues}
          axAgentValues={axAgentValues}
          setAgents={setAgents}
          agents={agents}
        />
      ) : (
        <SetupInstructions
          onStartChatbotProgressor={() => setStartChatbotProgressor(true)}
        />
      )}
    </>
  );
};
