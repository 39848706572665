import { ApiSelfServeV2Service } from 'api-clients/monolith';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { addMessageAction } from 'containers/FlashMessage/actions';

export default function useResubmitPartnerOptionData({
  successMessage,
  errorMessage,
}: {
  successMessage: string;
  errorMessage: string;
}) {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resubmitPartnerOptionData = async ({
    applicantId,
    partnerId,
    partnerStageId,
  }: {
    applicantId: string;
    partnerId: string;
    partnerStageId: string;
  }) => {
    setIsSubmitting(true);
    try {
      await ApiSelfServeV2Service.postApiSelfServeV2ApplicantsResubmitPartnerData(
        applicantId,
        {
          partner_id: partnerId,
          partner_stage_id: partnerStageId,
        },
      );

      dispatch(addMessageAction(successMessage, 'success'));
    } catch (e) {
      dispatch(addMessageAction(errorMessage, 'error'));
    } finally {
      setIsSubmitting(false);
    }
  };

  return { resubmitPartnerOptionData, isSubmitting };
}
