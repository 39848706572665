import { StyledReactSelect, Trashcan } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import React, { useState, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Error } from 'components/Error';
import { FilterLocationGroupDropdown } from 'components/FilterDropdown/FilterLocationGroupDropdown/FilterLocationGroupDropdown';
import { FilterOpeningDropdown } from 'components/FilterDropdown/FilterOpeningDropdown';
import { FilterStageDropdown } from 'components/FilterDropdown/FilterStageDropdown';
import { Filterable } from 'components/FilterDropdown/util';

import { conditionTypes } from '../constants';
import { messages } from '../messages';
import { useErrors } from './hooks/useErrors';
import { useStyles } from './styles';
import { ConditionBuilderProps, PreviousChange } from './types';

export const ConditionBuilder: VFC<ConditionBuilderProps> = ({
  condition,
  index,
  dynamicConditions,
  setDynamicConditions,
  handleChange,
  errors,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const [prevCondition, setPrevCondition] = useState<PreviousChange>({
    title: 'stage_titles',
    index: 0,
  });
  const {
    stageError,
    openingError,
    locationGroupError,
    setStageError,
    setOpeningError,
    setLocationGroupError,
  } = useErrors(errors);

  const handleDeleteCondition = (index: number) => {
    if (dynamicConditions.length > 1) {
      const updatedConditions = [...dynamicConditions];
      updatedConditions.splice(index, 1);
      setDynamicConditions(updatedConditions);
      switch (dynamicConditions[index].type) {
        case 'stage':
          handleChange({ stage_titles: undefined });
          break;
        case 'opening':
          handleChange({ opening_titles: undefined });
          break;
        case 'location_group':
          handleChange({ location_group_titles: undefined });
          break;
        default:
      }
    }
  };

  const handleConditionValueChange = (index: number, values: Filterable[]) => {
    const updatedConditions = [...dynamicConditions];
    updatedConditions[index].values = values;
    setDynamicConditions(updatedConditions);

    // Merge values into a single state for each type
    const mergedValues = updatedConditions
      .filter(cond => cond.type === updatedConditions[index].type)
      .flatMap(cond => cond.values);

    if (
      updatedConditions[index].type !== prevCondition.title &&
      index === prevCondition.index
    ) {
      handleChange({
        [prevCondition.title]: undefined,
      });
    }
    // Update state with merged values
    switch (updatedConditions[index].type) {
      case 'stage':
        handleChange({
          stage_titles: mergedValues.map(obj => obj.title),
        });
        setPrevCondition({ title: 'stage_titles', index });
        setStageError(false);
        break;
      case 'opening':
        handleChange({
          opening_titles: mergedValues.map(obj => obj.title),
        });
        setPrevCondition({ title: 'opening_titles', index });
        setOpeningError(false);
        break;
      case 'location_group':
        handleChange({
          location_group_titles: mergedValues.map(obj => obj.title),
        });
        setPrevCondition({ title: 'location_group_titles', index });
        setLocationGroupError(false);
        break;
      default:
        break;
    }
  };

  const cleanPreviousConditionValues = (type: string) => {
    switch (type) {
      case 'stage':
        handleChange({ stage_titles: undefined });
        break;
      case 'opening':
        handleChange({ opening_titles: undefined });
        break;
      case 'location_group':
        handleChange({ location_group_titles: undefined });
        break;
      default:
        break;
    }
  };

  const handleConditionTypeChange = (index: number, value: string) => {
    const updatedConditions = [...dynamicConditions];
    const currentCondition = updatedConditions[index];

    cleanPreviousConditionValues(currentCondition.type);

    currentCondition.type = value;
    currentCondition.values = [];

    handleConditionValueChange(index, []);

    setDynamicConditions(updatedConditions);
  };

  const availableConditionTypes = () => {
    const existingTypes = dynamicConditions.map(condition => condition.type);
    return conditionTypes.filter(conditionType => {
      return !existingTypes.includes(conditionType.value);
    });
  };

  return (
    <>
      {index > 0 && <div className={styles.separator}> AND </div>}
      <Grid className={styles.conditionBuilderContainer}>
        {/* Dropdown for selecting condition type */}
        <Grid>
          <StyledReactSelect
            label={intl.formatMessage(messages.conditionType)}
            aria-label={intl.formatMessage(messages.openingStage)}
            options={availableConditionTypes()}
            value={conditionTypes.find(type => type.value === condition.type)}
            onChange={(option: { value: string }) =>
              handleConditionTypeChange(index, option.value)
            }
            getOptionLabel={(option: { label: string }) => option.label}
            getOptionValue={(option: { value: string }) => option.value}
            className={styles.conditionType}
          />
        </Grid>
        {/* Dropdown for selecting condition value */}
        <Grid className={styles.conditionValue}>
          <Typography variant="body2" className={styles.nestedRequired}>
            <FormattedMessage {...messages.value} />
          </Typography>
          {condition.type === 'stage' && (
            <>
              <FilterStageDropdown
                selected={condition.values}
                setSelected={values =>
                  handleConditionValueChange(index, values)
                }
                showTitle
                enterprise
                error={stageError}
              />
              {stageError && <Error error={stageError} />}
            </>
          )}
          {condition.type === 'opening' && (
            <>
              <FilterOpeningDropdown
                selected={condition.values}
                setSelected={values =>
                  handleConditionValueChange(index, values)
                }
                showTitle
                enterprise
                error={openingError}
              />
              {openingError && <Error error={openingError} />}
            </>
          )}
          {condition.type === 'location_group' && (
            <>
              <FilterLocationGroupDropdown
                selected={condition.values}
                setSelected={values =>
                  handleConditionValueChange(index, values)
                }
                showTitle
                enterprise
                error={locationGroupError}
              />
              {locationGroupError && <Error error={locationGroupError} />}
            </>
          )}
        </Grid>
        {/* Delete button for removing condition */}
        {index > 0 && (
          <Trashcan
            viewBox="0 0 16 16"
            onClick={() => handleDeleteCondition(index)}
            className={styles.trashIcon}
          />
        )}
      </Grid>
    </>
  );
};
