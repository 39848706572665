import {
  Button,
  Input,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  TextButton,
} from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import {
  CancelablePromise,
  StageType,
  WorkflowEditorService,
  WorkflowStageDetail,
  WorkflowStageUpdates,
} from 'api-clients/monolith';
import { useSimpleToggle } from 'hooks';
import React, { FC, useContext, useState } from 'react';
import { classNames } from 'react-extras';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { Error } from 'components/Error';
import { HtmlEditor } from 'components/HtmlEditor';
import { InfoTooltip } from 'components/InfoTooltip';
import TextEditor from 'components/TextEditor/Loadable';
import { addMessageAction } from 'containers/FlashMessage/actions';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';
import useCreateTestApplicant from 'hooks/useCreateTestApplicant';

import { ConfirmationModal } from '../../../../../components/ConfirmationModal';
import { messages } from './messages';
import useStyles from './styles';

const HIDDEN_STAGE_BODY_STAGE_TYPES: StageType[] = [
  'BackgroundCheckerStage',
  'FountainDocumentSigningStage',
  'I9FormStage',
  'NewCheckrCanada::Stage',
  'SchedulerStage',
];

const HIDDEN_CUSTOM_SCRIPT_STAGE_TYPES: StageType[] = ['I9FormStage'];
const SHOW_NO_RESULTS_STAGE_TYPES: StageType[] = [
  'JobSelectorStage',
  'JobSwitcherStage',
];

export interface CustomizeStageModalProps {
  isOpen: boolean;
  onClose: () => void;
  funnelExternalId: string;
  funnelSlug: string;
}

export const CustomizeStageModal: FC<CustomizeStageModalProps> = ({
  isOpen,
  onClose,
  funnelExternalId,
  funnelSlug,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const classes = useStyles();

  const { stage, refetchStage } = useContext(StageContext);

  const [portalTitle, setPortalTitle] = useState(stage.portal_title ?? '');
  const [portalInstructions, setPortalInstructions] = useState(
    stage.portal_instructions,
  );
  const [portalBody, setPortalBody] = useState(stage.portal_body ?? '');
  const { showContent: portalBodyHTMLMode, toggle: togglePortalBodyHTMLMode } =
    useSimpleToggle(stage.extra.portal_body_html_mode ?? false);
  const [successMessage, setSuccessMessage] = useState(
    stage.extra.success_message ?? '',
  );
  const [portalJavascript, setPortalJavascript] = useState(
    stage.extra.portal_javascript ?? '',
  );
  const [successMessageJavascript, setSuccessMessageJavascript] = useState(
    stage.extra.success_message_javascript ?? '',
  );
  const [noResultsMessage, setNoResultsMessage] = useState(
    stage.extra.no_results_message ?? '',
  );
  const { showContent: noResultsHTMLMode, toggle: toggleNoResultsHTMLMode } =
    useSimpleToggle(stage.extra.no_results_html_mode ?? false);
  const showStageBodyField = !HIDDEN_STAGE_BODY_STAGE_TYPES.includes(
    stage.type,
  );

  const showCustomScriptSection = !HIDDEN_CUSTOM_SCRIPT_STAGE_TYPES.includes(
    stage.type,
  );

  const showNoResultsSection = SHOW_NO_RESULTS_STAGE_TYPES.includes(stage.type);

  const stageDataToSave = {
    workflow_stage: {
      stage: {
        no_results_message: noResultsMessage,
        no_results_html_mode: noResultsHTMLMode,
        portal_body: portalBody,
        portal_body_html_mode: portalBodyHTMLMode,
        portal_title: portalTitle,
        portal_instructions: portalInstructions,
        success_message: successMessage,
        portal_javascript: portalJavascript,
        success_message_javascript: successMessageJavascript,
      },
    },
  };

  const saveEditsSuccessCallback = () => {
    dispatch(
      addMessageAction(
        intl.formatMessage(messages.changesSavedSuccessfully),
        'success',
      ),
    );
    refetchStage();
  };

  const { result: saveEditsResult, mutation: saveEdits } =
    useApiServiceMutation<
      WorkflowStageDetail,
      (
        stageExternalId: string,
        funnelSlug: string,
        requestBody?: WorkflowStageUpdates,
      ) => CancelablePromise<WorkflowStageDetail>,
      { errors: Record<string, Array<string>> }
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.patchInternalApiWorkflowEditorFunnelsStages,
      {
        onSuccess: () => {
          saveEditsSuccessCallback();
          onClose();
        },
      },
    );

  const { handleCreateTestApplicant } = useCreateTestApplicant();

  const { result: saveEditsAndPreviewResult, mutation: saveEditsAndPreview } =
    useApiServiceMutation<
      WorkflowStageDetail,
      (
        stageExternalId: string,
        funnelSlug: string,
        requestBody?: WorkflowStageUpdates,
      ) => CancelablePromise<WorkflowStageDetail>,
      { errors: Record<string, Array<string>> }
    >(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      WorkflowEditorService.patchInternalApiWorkflowEditorFunnelsStages,
      {
        onSuccess: () => {
          saveEditsSuccessCallback();
          const windowReference = window.open('about:blank', '_blank');
          void handleCreateTestApplicant(
            funnelExternalId,
            stage.external_id,
            windowReference,
          );
          onClose();
        },
      },
    );

  const errors =
    (saveEditsResult.isError && saveEditsResult?.error?.errors) ||
    (saveEditsAndPreviewResult.isError &&
      saveEditsAndPreviewResult?.error?.errors) ||
    undefined;

  const onSaveEdits = () => {
    saveEdits(funnelSlug, stage.external_id, stageDataToSave);
  };

  const onSaveEditsAndPreview = () => {
    saveEditsAndPreview(funnelSlug, stage.external_id, stageDataToSave);
  };

  const isLoading =
    saveEditsResult.isLoading || saveEditsAndPreviewResult.isLoading;

  const isPortalTitleTooLong = portalTitle.length >= 100;

  const portalTitleErrorMessage =
    isPortalTitleTooLong && errors?.portal_title?.join(', ');

  interface HtmlEnabledSectionProps {
    onChange: React.Dispatch<React.SetStateAction<string>>;
    toggleHtmlMode: () => void;
    title: React.ReactNode;
    disableImageUpload?: boolean;
    enableLinkButton?: boolean;
    enableVideoButton?: boolean;
    htmlBodyTestId?: string;
    htmlModeEnabled: boolean;
    textBodyTestId?: string;
    textEditorId: string;
    textEditorPlaceholder?: React.ReactNode;
    value: string;
  }

  const HtmlEnabledSection = ({
    onChange,
    toggleHtmlMode,
    disableImageUpload,
    enableLinkButton,
    enableVideoButton,
    htmlBodyTestId,
    htmlModeEnabled,
    textBodyTestId,
    textEditorId,
    textEditorPlaceholder,
    value,
    title,
  }: HtmlEnabledSectionProps) => {
    const {
      showContent: isConfirmModalOpen,
      on: openConfirmModal,
      off: closeConfirmModal,
    } = useSimpleToggle();

    const handleToggleConfirmation = () => {
      onChange('');
      toggleHtmlMode();
      closeConfirmModal();
    };

    const handleEditModeToggle = () => {
      if (value) {
        openConfirmModal();
      } else {
        toggleHtmlMode();
      }
    };

    return (
      <>
        <Grid container justify="space-between">
          {title}
          <Grid>
            <TextButton color="primary" onClick={handleEditModeToggle}>
              {htmlModeEnabled
                ? intl.formatMessage(messages.useVisualEditor)
                : intl.formatMessage(messages.useCodeEditor)}
            </TextButton>
          </Grid>
        </Grid>
        {htmlModeEnabled ? (
          <Grid data-testid={htmlBodyTestId}>
            <HtmlEditor value={value} onChange={onChange} />
          </Grid>
        ) : (
          <Grid data-testid={textBodyTestId}>
            <TextEditor
              disableImageUpload={disableImageUpload}
              enableLinkButton={enableLinkButton}
              enableVideoButton={enableVideoButton}
              id={textEditorId}
              placeholder={textEditorPlaceholder}
              onChange={onChange}
              value={value}
            />
          </Grid>
        )}
        {isConfirmModalOpen && (
          <ConfirmationModal
            ariaLabelledBy={intl.formatMessage(messages.changeEditor)}
            bodyContent={intl.formatMessage(messages.changeEditorInfo)}
            cancelButtonContent={<FormattedMessage {...messages.cancel} />}
            confirmButtonContent={<FormattedMessage {...messages.continue} />}
            maxWidth={420}
            negative
            onCancel={closeConfirmModal}
            onConfirm={handleToggleConfirmation}
            title={intl.formatMessage(messages.changeEditor)}
          />
        )}
      </>
    );
  };
  return (
    <>
      <Modal
        ariaLabelledBy={intl.formatMessage(messages.customizeStageModal)}
        disableBackdropClick
        fullScreenOnMobile
        onClose={onClose}
        open={isOpen}
      >
        <ModalHeader
          ariaLabelledBy={intl.formatMessage(messages.customizeStage)}
          onClose={onClose}
          showIcon={false}
        >
          {intl.formatMessage(messages.customizeStage)}
        </ModalHeader>
        <ModalContent dividers>
          <Typography variant="body2">
            {intl.formatMessage(messages.modalDescription)}
          </Typography>
          <Grid className={classes.section}>
            <Typography variant="h3" className={classes.sectionHeader}>
              {intl.formatMessage(messages.applicantFacingContent)}
            </Typography>
            <Grid className={classes.fieldContent}>
              <Input
                autoFocus
                value={portalTitle}
                placeholder={intl.formatMessage(messages.addStageTitle)}
                onChange={e => setPortalTitle(e.target.value)}
                label={intl.formatMessage(messages.stageName)}
                error={isPortalTitleTooLong || Boolean(portalTitleErrorMessage)}
              />
              <Error error={portalTitleErrorMessage} align="right" />
              <Typography
                data-testid="portal-title-character-count"
                variant="body2"
                align="right"
                className={classNames(classes.characterCount, {
                  error: Boolean(portalTitleErrorMessage),
                })}
                color={isPortalTitleTooLong ? 'error' : 'initial'}
              >
                {intl.formatMessage(messages.characterCount, {
                  count: portalTitle?.length,
                })}
              </Typography>
            </Grid>
            <Grid className={classes.fieldContent}>
              <Typography
                color="textSecondary"
                variant="body2"
                className={classes.fieldLabel}
              >
                {intl.formatMessage(messages.stageInstructions)}
              </Typography>
              <TextEditor
                enableLinkButton
                enableVideoButton
                id="stage-instructions"
                onChange={setPortalInstructions}
                placeholder={intl.formatMessage(
                  messages.stageInstructionsPlaceholder,
                )}
                value={portalInstructions}
              />
            </Grid>
            {showStageBodyField &&
              HtmlEnabledSection({
                onChange: setPortalBody,
                toggleHtmlMode: togglePortalBodyHTMLMode,
                disableImageUpload: true,
                enableLinkButton: true,
                enableVideoButton: true,
                htmlBodyTestId: 'stage-body-html',
                htmlModeEnabled: portalBodyHTMLMode,
                textBodyTestId: 'stage-body-text',
                textEditorId: 'stage-body',
                textEditorPlaceholder: intl.formatMessage(
                  messages.stageInstructionsPlaceholder,
                ),
                value: portalBody,
                title: (
                  <Grid>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      className={classes.fieldLabel}
                    >
                      {intl.formatMessage(messages.stageBody)}
                    </Typography>
                  </Grid>
                ),
              })}
            {stage.has_success_message && (
              <Grid className={classes.fieldContent}>
                <Typography
                  color="textSecondary"
                  variant="body2"
                  className={classes.fieldLabel}
                >
                  {intl.formatMessage(messages.successMessage)}
                </Typography>
                <TextEditor
                  disableImageUpload
                  enableLinkButton
                  enableVideoButton
                  id="success-message"
                  onChange={setSuccessMessage}
                  value={successMessage}
                />
              </Grid>
            )}
          </Grid>
          {showNoResultsSection &&
            HtmlEnabledSection({
              onChange: setNoResultsMessage,
              toggleHtmlMode: toggleNoResultsHTMLMode,
              disableImageUpload: true,
              enableLinkButton: true,
              htmlBodyTestId: 'no-results-html',
              htmlModeEnabled: noResultsHTMLMode,
              textBodyTestId: 'no-results-text',
              textEditorId: 'no-results-body',
              textEditorPlaceholder: intl.formatMessage(
                messages.noResultsPlaceholder,
              ),
              value: noResultsMessage,
              title: (
                <Grid>
                  <Grid container alignItems="center">
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      className={classes.fieldLabel}
                    >
                      {intl.formatMessage(messages.noResults)}
                    </Typography>
                    <Grid className={classes.fieldTooltip}>
                      <InfoTooltip
                        title={intl.formatMessage(messages.noResultsInfo)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ),
            })}
          {showCustomScriptSection && (
            <Grid className={classes.section} data-testid="custom-script-text">
              <Typography variant="h3" className={classes.sectionHeader}>
                {intl.formatMessage(messages.customScripts)}
              </Typography>
              <Grid className={classes.fieldContent}>
                <Grid container alignItems="center">
                  <Grid>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      className={classes.fieldLabel}
                    >
                      {intl.formatMessage(messages.stageScript)}
                    </Typography>
                  </Grid>
                  <Grid className={classes.fieldTooltip}>
                    <InfoTooltip
                      title={intl.formatMessage(messages.stageScriptInfo)}
                    />
                  </Grid>
                </Grid>
                <HtmlEditor
                  value={portalJavascript}
                  onChange={setPortalJavascript}
                />
              </Grid>
              {stage.has_success_message && (
                <Grid>
                  <Grid container alignItems="center">
                    <Grid>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        className={classes.fieldLabel}
                      >
                        {intl.formatMessage(messages.postCompletionScript)}
                      </Typography>
                    </Grid>
                    <Grid className={classes.fieldTooltip}>
                      <InfoTooltip
                        title={intl.formatMessage(
                          messages.postCompletionScriptInfo,
                        )}
                      />
                    </Grid>
                  </Grid>
                  <HtmlEditor
                    value={successMessageJavascript}
                    onChange={setSuccessMessageJavascript}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </ModalContent>
        <ModalFooter>
          <Button
            aria-label={intl.formatMessage(messages.cancel)}
            onClick={onClose}
            size="small"
            type="secondary"
            disabled={isLoading}
            autoWidth
          >
            {intl.formatMessage(messages.cancel)}
          </Button>
          <Button
            aria-label={intl.formatMessage(messages.saveEdits)}
            onClick={onSaveEdits}
            size="small"
            disabled={isLoading}
            autoWidth
          >
            {intl.formatMessage(messages.saveEdits)}
          </Button>
          <Button
            aria-label={intl.formatMessage(messages.saveAndPreview)}
            onClick={onSaveEditsAndPreview}
            size="small"
            disabled={isLoading}
            autoWidth
          >
            {intl.formatMessage(messages.saveAndPreview)}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
