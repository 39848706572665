import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    conditionBuilderContainer: {
      display: 'flex',
      gap: '16px',
      marginTop: theme.spacing(2),
    },
    conditionType: {
      minWidth: '195px',
      '& p': {
        '&::after': {
          content: "'*'",
          color: theme.palette.common.red400,
          margin: theme.spacing(0.5),
        },
      },
    },
    conditionValue: {
      position: 'relative',
      flexGrow: 1,
      '& > div': {
        display: 'flex',
        flexDirection: 'column',
        '& > div:nth-child(2)': {
          position: 'inherit',
          width: '100%',
          marginTop: 'auto',
        },
        '& > div': {
          width: '100%',
          marginTop: theme.spacing(0.5),
          minHeight: '40px',

          '& > div': {
            width: '100%',
            display: 'flex',
            flexWrap: 'wrap',
            '& > div': {
              borderRadius: '3px',
              margin: '4px 6px',
              zIndex: 100,
              '& > p': {
                fontSize: '14px',
              },
            },
            '& > div:last-child': {
              '& > img': {
                position: 'absolute',
                top: '50%',
                right: '15px',
              },
            },
          },
        },
      },
    },
    nestedRequired: {
      '&::after': {
        content: "'*'",
        color: theme.palette.common.red400,
        margin: theme.spacing(0.5),
      },
    },
    trashIcon: {
      alignSelf: 'center',
      fontSize: '14px',
    },
    separator: {
      width: 'max-content',
      fontWeight: 700,
      marginTop: theme.spacing(2),
      padding: theme.spacing(0.5, 1),
      background: theme.palette.common.gray100,
      fontSize: '12px',
      borderRadius: '5px',
      color: theme.palette.common.black,
    },
  }),
);
