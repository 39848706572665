/* eslint-disable camelcase */
import {
  Button,
  InnerContent,
  ModalFooter,
} from '@fountain/fountain-ui-components';
import { Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

import { AssessmentFormData } from 'containers/StatusLabels/utils';
import globalMessages from 'shared/global/messages';

import { messages } from '../messages';
import AssessmentDataFieldQuestions from './AssessmentDataFieldQuestions';

export interface AssessmentFormModalContentProps {
  assessmentData: AssessmentFormData;
  handleClose: () => void;
}

export const AssessmentFormModalContent: VFC<
  AssessmentFormModalContentProps
> = ({ assessmentData, handleClose }) => {
  const completionDate = (
    <FormattedDate value={assessmentData.completed_at} dateStyle="short" />
  );
  const score = `${assessmentData.score}/${
    Object.keys(assessmentData.response_data).length
  }`;

  return (
    <>
      <InnerContent style={{ padding: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h4" color="textPrimary">
            <FormattedMessage
              {...messages.answeredCorrect}
              values={{ score }}
            />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              {...messages.completionDate}
              values={{ completionDate }}
            />
          </Typography>
        </div>
        <AssessmentDataFieldQuestions
          questions={assessmentData.response_data}
        />
      </InnerContent>
      <ModalFooter>
        <Button autoWidth onClick={handleClose} size="small" type="primary">
          <FormattedMessage {...globalMessages.close} />
        </Button>
      </ModalFooter>
    </>
  );
};

export default AssessmentFormModalContent;
