import PropTypes from 'prop-types';

import { DETAIL_TYPES } from './constants';

export const IdType = PropTypes.oneOfType([PropTypes.number, PropTypes.string]);

export const DetailOptionsType = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
]);

export const DetailTypeType = PropTypes.oneOf([
  DETAIL_TYPES.address,
  DETAIL_TYPES.availability,
  DETAIL_TYPES.checkboxes,
  DETAIL_TYPES.date_picker,
  DETAIL_TYPES.dropdown,
  DETAIL_TYPES.license_data,
  DETAIL_TYPES.radio,
  DETAIL_TYPES.text_area,
  DETAIL_TYPES.text_field,
]);

export const DetailValueType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.object,
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.bool),
  PropTypes.arrayOf(PropTypes.object),
  PropTypes.arrayOf(PropTypes.string),
]);

export const DetailType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  option_bank_id: IdType,
  options: DetailOptionsType,
  type: DetailTypeType,
  value: DetailValueType,
});

export const DetailsType = PropTypes.shape({
  data: DetailType,
  secure_data: DetailType,
  structured_data: DetailType,
});
