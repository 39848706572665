import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useKnowledgeBaseStyles = makeStyles<Theme>(theme => ({
  container: {
    width: '100%',
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  header: {
    fontSize: theme.typography.h2.fontSize,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
  },
}));

export const useListKnowledgeBaseStyles = makeStyles<Theme>(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  item: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    height: '40px',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
    background: theme.palette.common.gray100,
    height: '64px',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: '5px',
  },
  buttons: {
    display: 'flex',
    gap: theme.spacing(2),
    minWidth: '235px',
  },
  button: {
    fontSize: theme.typography.fontSize,
    gap: theme.spacing(1),
    '& span': {
      margin: 0,
      color: theme.palette.common.gray800,
    },
  },
  fileName: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    '& > svg': {
      color: theme.palette.common.green400,
    },
  },
}));

export const useStateStyles = makeStyles<Theme>(() => ({
  container: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
}));
