import { WorkflowPartnerStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { PartnerRules } from 'containers/WorkflowEditor/components/Rules/PartnerRules';
import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { StageSettingMessageCard } from '../../../StageSettingMessageCard';
import { PartnerIntegrationCard } from './PartnerIntegrationCard';

export interface PartnerStageProps {
  stage: WorkflowPartnerStage;
}

export const PartnerStage: VFC<PartnerStageProps> = ({ stage }) => {
  return (
    <div data-testid="partnerStage">
      <StageSection>
        <PartnerIntegrationCard stage={stage} />
      </StageSection>
      <StageSection>
        <PartnerRules />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="automated"
          stageExternalId={stage.external_id}
        />
      </StageSection>
      <StageSection>
        <StageSettingMessageCard
          messageType="trigger"
          stageExternalId={stage.external_id}
        />
      </StageSection>
    </div>
  );
};
