import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  button: {
    borderRadius: '5px',
    width: 'fit-content',
    padding: theme.spacing(1.5, 3),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.common.gray500,
  },
  deleteButton: {
    width: 'auto',
    border: 'none',
    boxShadow: 'none',
    color: theme.palette.common.red400,
    marginRight: 'auto',
  },
  dialogButton: {
    margin: theme.spacing(1, 1),
  },
  dialogDeleteButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': { backgroundColor: theme.palette.error.dark },
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  saveButton: {
    borderRadius: '5px',
    width: '80px',
    padding: theme.spacing(1.5, 3),
  },
  title: {
    fontSize: '20px',
  },
}));
