import { useEffect, useState } from 'react';

import { splitOption } from '../../types';

export const useSplitOptions = ({
  startTime,
  endTime,
  noSplitLabel,
}: {
  startTime: string;
  endTime: string;
  noSplitLabel: string;
}) => {
  const [splitOptions, setSplitOptions] = useState<splitOption[]>([]);
  const [splitOption, setSplitOption] = useState<splitOption>(splitOptions[0]);

  const generateSplitOptions = (
    startTime: string,
    endTime: string,
    noSplitLabel: string,
  ) => {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const durationInMs = end.getTime() - start.getTime();
    const durationInMinutes = durationInMs / (1000 * 60); // Convert milliseconds to minutes

    if (durationInMinutes <= 0) {
      return [];
    }

    const splitOptions: splitOption[] = [];

    let option = 15; // Start from 15 instead of 0 to avoid division by zero
    while (option <= durationInMinutes / 2) {
      if (durationInMinutes % option === 0) {
        splitOptions.push({ label: `${option} minutes`, value: option });
      }
      option += 15;
    }

    [10, 20, 40].forEach(time => {
      if (durationInMinutes % time === 0) {
        splitOptions.push({ label: `${time} minutes`, value: time });
      }
    });

    const noSplitOption = { label: noSplitLabel, value: durationInMinutes };
    splitOptions.sort((a, b) => a.value - b.value).unshift(noSplitOption);
    return splitOptions;
  };

  useEffect(() => {
    const generatedSplitOptions = generateSplitOptions(
      startTime,
      endTime,
      noSplitLabel,
    );
    setSplitOptions(generatedSplitOptions);
    setSplitOption(generatedSplitOptions[0]);
  }, [startTime, endTime, noSplitLabel]);

  return {
    splitOptions,
    splitOption,
    setSplitOption,
  };
};
