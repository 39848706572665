import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    color: theme.palette.common.blue400,
  },
  grid: {
    padding: theme.spacing(2),
  },
  refreshButton: {
    height: 32,
    width: 32,
  },
  selectOptionDropdown: {
    width: 362,
    marginRight: theme.spacing(2),
  },
}));
