import { Button } from '@fountain/fountain-ui-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  CancelablePromise,
  EventsService,
  Unauthorized,
} from 'api-clients/monolith';
import cx from 'classnames';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { addMessageAction } from 'containers/FlashMessage/actions';
import { useApiServiceMutation } from 'hooks/useApiServiceMutation';

import { messages } from '../messages';
import { FooterProps } from '../types';
import { useStyles } from './styles';

export const Footer: React.VFC<FooterProps> = ({
  isUpdatingEvent,
  isSaveButtonEnabled,
  resetForm,
  redirectToCalendar,
  availableSlot,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const editSeries = !!urlSearchParams.get('editSeries');

  const isUpdate: boolean = availableSlot?.external_id !== undefined;

  const [isDeleteEventDialogOpen, setIsDeleteEventDialogOpen] =
    useState<boolean>(false);

  const handleCloseDialog = () => {
    setIsDeleteEventDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setIsDeleteEventDialogOpen(true);
  };

  const { mutation: deleteEvent, result: deleteResult } = useApiServiceMutation<
    void,
    (
      eventExternalId: string,
      {
        // eslint-disable-next-line camelcase
        delete_siblings,
      }: {
        // eslint-disable-next-line camelcase
        delete_siblings: boolean;
      },
    ) => CancelablePromise<void>,
    Unauthorized
    // eslint-disable-next-line @typescript-eslint/unbound-method
  >(EventsService.deleteInternalApiEvents, {
    onSuccess: () => {
      const successMessage = editSeries
        ? messages.seriesDeleteSuccess
        : messages.sessionDeleteSuccess;
      dispatch(addMessageAction(intl.formatMessage(successMessage), 'success'));
      redirectToCalendar();
    },
    onError: () => {
      dispatch(
        addMessageAction(
          intl.formatMessage(messages.eventDeleteFailed),
          'error',
        ),
      );
    },
  });

  const handleDelete = () => {
    if (!availableSlot?.external_id) return;
    deleteEvent(availableSlot?.external_id, { delete_siblings: editSeries });
  };

  const deleteMessage = editSeries
    ? messages.deleteSeries
    : messages.deleteSession;

  const ConfirmationDeleteDialog = () => {
    const dialogContent = editSeries
      ? messages.deleteSeriesConfirmation
      : messages.deleteSessionConfirmation;
    return (
      <Dialog
        open={isDeleteEventDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="footer-delete-dialog-title"
        aria-describedby="footer-delete-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={styles.title}>
          <Typography variant="h2">
            <FormattedMessage {...deleteMessage} />
          </Typography>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body2">
            <FormattedMessage {...dialogContent} />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            type="secondary"
            onClick={handleCloseDialog}
            className={styles.dialogButton}
          >
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            variant="contained"
            type="primary"
            onClick={handleDelete}
            className={cx(styles.dialogButton, styles.dialogDeleteButton)}
            isLoading={deleteResult.isLoading}
          >
            <FormattedMessage {...deleteMessage} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <div className={styles.footer}>
      {isUpdate && (
        <Button
          type="secondary"
          className={styles.deleteButton}
          onClick={handleOpenDialog}
        >
          <FormattedMessage {...deleteMessage} />
        </Button>
      )}
      <Button type="secondary" className={styles.button} onClick={resetForm}>
        <FormattedMessage {...messages.cancel} />
      </Button>
      <Button
        type="primary"
        submit
        className={styles.saveButton}
        isLoading={isUpdatingEvent}
        disabled={!isSaveButtonEnabled}
      >
        <FormattedMessage {...messages.save} />
      </Button>
      <ConfirmationDeleteDialog />
    </div>
  );
};
